@include export-module('excel-filter-bootstrap5-icons') {

  .e-icon-check::before {
    content: '\e774';
  }

  #{&}.e-excelfilter {
    .e-icon-filter::before,
    .e-excl-filter-icon::before {
      content: '\e7f7';
    }

    .e-excl-filter-icon.e-filtered::before {
      content: '\e72c';
    }

    .e-search-icon::before,
    .e-ccsearch-icon::before {
      content: '\e754';
    }

    .e-chkcancel-icon::before {
      content: '\e7e7';
    }

    .e-sortascending::before {
      content: '\e7a3';
    }

    .e-sortdescending::before {
      content: '\e7b6';
    }
  }
}
