/*! component's theme wise override definitions and variables */
@include export-module('richtexteditor-material-icons') {

  /*! richtexteditor icons */

  .e-toolbar-wrapper,
  .e-toolbar-container,
  .e-rte-toolbar,
  .e-rte-dropdown-popup {

    .e-blockquote::before {
      content: '\e8f5';
    }
    .e-alignments::before {
      content: '\e7b8';
    }

    .e-justify-left::before {
      content: '\e7b8';
    }

    .e-justify-center::before {
      content: '\e813';
    }

    .e-justify-right::before {
      content: '\e719';
    }

    .e-justify-full::before {
      content: '\e721';
    }

    .e-font-name::before {
      content: '\e76f';
    }

    .e-rte-font-color::before {
      content: '\e79f';
    }

    .e-background-color::before {
      content: '\e783';
    }

    .e-bold::before {
      content: '\e737';
    }

    .e-italic::before {
      content: '\e75a';
    }

    .e-underline::before {
      content: '\e82f';
    }

    .e-strike-through::before {
      content: '\e758';
    }

    .e-clear-format::before {
      content: '\e803';
    }

    .e-clear-all::before {
      content: '\e7cc';
    }

    .e-cut::before {
      content: '\e7fb';
    }

    .e-copy::before {
      content: '\e77c';
    }

    .e-paste::before {
      content: '\e70b';
    }

    .e-unorder-list::before {
      content: '\e77e';
    }

    .e-order-list::before {
      content: '\e7cb';
    }

    .e-indent::before {
      content: '\e810';
    }

    .e-outdent::before {
      content: '\e72a';
    }

    .e-undo::before {
      content: '\e713';
    }

    .e-redo::before {
      content: '\e755';
    }

    .e-super-script::before {
      content: '\e7a7';
    }

    .e-sub-script::before {
      content: '\e80a';
    }

    .e-create-link::before {
      content: '\e757';
    }

    .e-open-link::before {
      content: '\e77a';
    }

    .e-edit-link::before {
      content: '\e722';
    }

    .e-remove-link::before {
      content: '\e80c';
    }

    .e-image::before {
      content: '\e786';
    }

    .e-audio::before {
      content: '\e894';
    }

    .e-video::before {
      content: '\e895';
    }

    .e-replace::before {
      content: '\e710';
    }

    .e-audio-replace::before,
    .e-video-replace::before {
      content: '\e772';
    }

    .e-align::before {
      content: '\e7b8';
    }

    .e-caption::before {
      content: '\e8e2';
    }

    .e-remove::before,
    .e-audio-remove::before,
    .e-video-remove::before {
      content: '\e820';
    }

    .e-insert-link::before {
      content: '\e757';
    }

    .e-display::before,
    .e-audio-display::before,
    .e-video-display::before {
      content: '\e7b7';
    }

    .e-alt-text::before {
      content: '\e769';
    }

    .e-img-dimension::before,
    .e-video-dimension::before {
      content: '\e879';
    }

    .e-maximize::before {
      content: '\e81c';
    }

    .e-minimize::before {
      content: '\e7b0';
    }

    .e-zoom-in::before {
      content: '\e795';
    }

    .e-zoom-out::before {
      content: '\e825';
    }

    .e-lower-case::before {
      content: '\e746';
    }

    .e-upper-case::before {
      content: '\e793';
    }

    .e-print::before {
      content: '\e75d';
    }

    .e-formats::before {
      content: '\e844';
    }

    .e-source-code::before {
      content: '\e80e';
    }

    .e-preview::before {
      content: '\e7de';
    }

    .e-view-side::before {
      content: '\e82b';
    }

    .e-inline-code::before {
      content: '\e831';
    }

    .e-preformat-code::before {
      content: '\e90f';
    }

    .e-table-header::before {
      content: '\e8f4';
    }

    .e-table-remove::before {
      content: '\e811';
    }

    .e-table-rows::before {
      content: '\e77f';
    }

    .e-table-columns::before {
      content: '\e841';
    }

    .e-table-cell-ver-align::before {
      content: '\e74f';
    }

    .e-table-edit-properties::before {
      content: '\e730';
    }

    .e-create-table::before {
      content: '\e83f';
    }

    .e-align-bottom::before {
      content: '\e7a0';
    }

    .e-align-middle::before {
      content: '\e74f';
    }

    .e-align-top::before {
      content: '\e707';
    }

    .e-insert-column-left::before {
      content: '\e78b';
    }

    .e-insert-column-right::before {
      content: '\e70e';
    }

    .e-delete-column::before {
      content: '\e714';
    }

    .e-insert-row-before::before {
      content: '\e836';
    }

    .e-insert-row-after::before {
      content: '\e801';
    }

    .e-delete-row::before {
      content: '\e7f2';
    }

    .e-style::before {
      content: '\e76e';
    }

    .e-rte-file-manager::before {
      content: '\e83c';
    }

    .e-rte-format-painter::before {
      content: '\e8bd';
    }
    
    .e-rte-export-pdf::before {
      content: '\e8fb';
    }

    .e-rte-import-doc::before {
      content: '\e8fc';
    }

    .e-rte-export-doc::before {
      content: '\e8fd';
    }
  }

  .e-richtexteditor {
    .e-south-east::before,
    .e-south-west::before {
      content: '\e761';
    }
  }

  .e-rtl {
    .e-rte-toolbar,
    .e-rte-dropdown-popup {
      .e-unorder-list::before {
        content: '\e8c4';
      }
  
      .e-order-list::before {
        content: '\e8c5';
      }
  
      .e-indent::before {
        content: '\e8c6';
      }
  
      .e-outdent::before {
        content: '\e8c7';
      }
    }
  }

  .e-cell-merge::before {
    content: '\e83a';
  }

  .e-cell-horizontal-split::before {
    content: '\e7a4';
  }

  .e-cell-vertical-split::before {
    content: '\e732';
  }

  .e-table-cell::before {
    content: '\e7c2';
  }
  
  .e-rte-cursor-brush {
    cursor: $rte-format-painter-cursor;
  }
}
