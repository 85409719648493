@include export-module('menu-bootstrap5-icons') {
  .e-menu-wrapper,
  .e-menu-container {
    @at-root {
      & ul {
        &.e-vertical .e-menu-item {
          & .e-caret {
            &::before {
              content: '\e748';
            }
          }
        }

        & .e-menu-item  {
          & .e-caret {
            &::before {
              content: '\e729';
            }
          }
        }
      }

      & .e-ul .e-menu-item {
        & .e-caret {
          &::before {
            content: '\e748';
          }
        }
      }
    }

    &.e-hamburger {
      & .e-menu-header {
        & .e-menu-icon::before {
          content: '\e799';
        }
      }

      &.e-close-icon {
        & .e-menu-header {
          & .e-menu-icon::before {
            content: '\e7e7';
          }
        }
      }

      & .e-vertical .e-menu-item .e-caret,
      & .e-menu-item .e-caret {
        &::before {
          content: '\e729';
        }
      }
    }

    &.e-rtl.e-hamburger ul {
      &.e-ul {
        & .e-caret {
          &::before {
            content: '\e729';
          }
        }
      }
    }

    & .e-menu-hscroll.e-hscroll .e-nav-left-arrow::before,
    & .e-menu-hscroll.e-hscroll .e-nav-right-arrow::before {
      content: '\e748';
    }

    & .e-menu-vscroll.e-vscroll .e-nav-up-arrow::before,
    & .e-menu-vscroll.e-vscroll .e-nav-down-arrow::before {
      content: '\e729';
    }
  }

  .e-rtl.e-menu-wrapper ul,
  .e-rtl.e-menu-container ul,
  .e-bigger .e-rtl.e-menu-wrapper ul,
  .e-bigger.e-rtl.e-menu-wrapper ul,
  .e-bigger .e-rtl.e-menu-container ul,
  .e-bigger.e-rtl.e-menu-container ul {
    &.e-vertical,
    &.e-ul {
      & .e-caret {
        &::before {
          content: '\e765';
        }
      }
    }
  }

  .e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul,
  .e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul,
  .e-bigger .e-rtl.e-menu-container.e-hamburger ul,
  .e-bigger.e-rtl.e-menu-container.e-hamburger ul {
    &.e-ul {
      & .e-caret {
        &::before {
          content: '\e729';
        }
      }
    }
  }
}
