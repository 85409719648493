.grid-settings-tab {
  display: block;
  position: absolute;
  top: 40%;
  margin-top: 50px;
  margin-left: -24px;
  text-align: center;
  background-color: $primary-light;
  border: 1px solid rgba($color: $primary, $alpha: 0.5);
  border-right: none;
  border-radius: $border-radius 0 0 $border-radius;
}

.grid-settings-tab > a {
  @extend .d-block;

  width: 24px;
}

.grid-view-picker,
.grid-filter-picker {
  @extend .d-inline-block;
  @extend .mb-3;
}

.grid-view-picker input,
.grid-filter-picker input {
  border: none;
  background-color: transparent;
}
