.sub-navbar {
  @extend .fixed-top;

  z-index: 1025; // Behind search dropdown (drops down from navbar at 1035)
  margin-top: $navbar-height;
  background-color: $primary-light;
  border-bottom: 1px solid scale-color($primary, $lightness: 60%, $saturation: -20%);
}

.sub-navbar .nav {
  margin-bottom: 0 !important;
}

.sub-navbar .nav-link {
  @extend .py-1;
  @extend .px-3;

  margin: 3px 0 -1px 0;
  border-bottom: 3px solid transparent;
}

.sub-navbar .nav-link.active {
  border-color: $primary;
}

.sub-navbar .nav-link.active:hover {
  color: $primary;
}
