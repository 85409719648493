@mixin stepper-styles($step-width, $icon-size, $text-size, $optional-size) {
  &.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) {
    .e-icons.e-step-indicator {
      font-size: $step-width;
    }
    &.e-step-notstarted .e-icons.e-step-indicator {
      width: $step-width;
      height: $step-width;
    }
  }

  .e-step-container {
    .e-step,
    .e-indicator {
      min-width: $step-width;
      min-height: $step-width;
    }

    .e-indicator {
      font-size: $icon-size;
    }

    .e-step-text-container,
    .e-step-label-container {
      font-size: $text-size;
    }

    .e-step-content {
      font-size: $icon-size;
    }

    .e-step-label-optional {
      font-size: $optional-size;
    }
  }
  &.e-vertical .e-step-label-container.e-label-after .e-step-label-optional {
    font-size: $optional-size;
  }
}

@include export-module('stepper-layout') {
  .e-stepper {
    position: relative;
    .e-step-selected {
      .e-text,
      .e-label {
        font-weight: $stepper-font-weight;
      }
    }
    &.e-linear {
      .e-step-container {
        pointer-events: none;
      }
      .e-previous:not(.e-step-disabled),
      .e-next:not(.e-step-disabled),
      .e-step-selected:not(.e-step-disabled) {
        pointer-events: auto;
      }
    }
    &.e-stepper-readonly,
    .e-step-disabled {
      cursor: default;
      pointer-events: none;
    }
    &.e-step-type-indicator .e-step-container {
      &:not(.e-step-item.e-step-label.e-step-text) {
        .e-icons.e-step-indicator {
          font-size: $stepper-step-width;
          border-radius: $stepper-step-radius;
        }
      }
    }

    &.e-step-type-indicator .e-step-content,
    &.e-step-type-label .e-step-content {
      padding: 0 8px;
    }

    //For Blazor Stepper Tooltip
    .e-step-container:has(.e-tooltip-wrap.e-stepper-tooltip),
    &.e-vertical.e-label-after .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip),
    &.e-vertical.e-label-before .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip) {
      position: relative;
    }
    &.e-horizontal .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip) {
      position: unset;
    }
    .e-tooltip-wrap.e-stepper-tooltip {
      display: none;
      justify-content: center;
      left: 50%;
      top: -$stepper-tooltip-tip-bottom-height;
      transform: translate(-50%, -100%);

      &.e-show-tooltip {
        display: inline-flex;
      }
      .e-tip-content {
        width: max-content;
      }
      .e-arrow-tip.e-tip-bottom {
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
      }
      .e-arrow-tip-inner.e-tip-bottom {
        top: -6px;
      }
    }
    .e-step-item.e-step-label .e-tooltip-wrap.e-stepper-tooltip {
      left: unset;
      top: 0;
      transform: translate(0%, -135%);
    }
    &.e-step-type-indicator .e-tooltip-wrap.e-stepper-tooltip {
      transform: translate(-50%, -115%);
    }
    .e-stepper-steps {
      display: flex;
      padding: 0;
      margin: 0;
      justify-content: space-between;
      position: relative;
      align-items: flex-start;
      .e-step-container,
      .e-step,
      .e-step-valid.e-step-text.e-step-text-only .e-step-text-container,
      .e-step-error.e-step-text.e-step-text-only .e-step-text-container,
      .e-step-label-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .e-step-container {
      max-width: var(--max-width);
      list-style-type: none;
      cursor: pointer;
      text-align: center;
      .e-step-label-container,
      .e-step-text-container {
        max-width: clamp(100%, 10em, 100%);
        white-space: nowrap;
        font-size: $stepper-text-size;
      }
      .e-step-label-optional {
        font-size: $stepper-optional-size;
        flex-basis: 100%;
      }
      .e-step-optional-container {
        display: flex;
        flex-direction: column;
      }
      .e-step-content {
        font-size: $stepper-icon-size;
        line-height: 0;
      }
      .e-indicator {
        font-size: $stepper-icon-size;
      }
      &:not(.e-step-text-only) > .e-step-text-container {
        padding: $stepper-icon-text-padding;
      }
      &.e-step-text-only .e-step-text-container,
      &.e-step-label-only .e-step-label-container {
        padding: $stepper-text-padding;
        padding-block: 5px;
      }
      &.e-step-text-only.e-step-error .e-step-text-container,
      &.e-step-text-only.e-step-valid .e-step-text-container,
      &.e-step-label-only.e-step-error .e-step-label-container,
      &.e-step-label-only.e-step-valid .e-step-label-container {
        flex-direction: column;
        .e-step-validation-icon {
          position: relative;
          top: $stepper-validation-position;
        }
      }
      .e-step,
      .e-indicator {
        border-radius: $stepper-step-radius;
        min-width: $stepper-step-width;
        min-height: $stepper-step-width;
      }
    }
    .e-step-notstarted:not(.e-step-disabled) .e-step,
    .e-step-notstarted:not(.e-step-disabled) .e-indicator {
      border: $step-border;
    }
    &.e-horizontal {
      display: block;
      .e-stepper-steps {
        flex-direction: row;
      }
      &.e-label-before,
      &.e-label-after {
        &:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar {
          margin-left: var(--progress-left-position);
          top: var(--progress-top-position);
          width: var(--progress-bar-width);
        }
      }
      &.e-label-before.e-rtl,
      &.e-label-after.e-rtl {
        &:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar {
          margin-right: var(--progress-left-position);
          margin-left: unset;
        }
      }
      .e-step-item.e-step-label {
        gap: 1em;
        flex-direction: column;
      }
      .e-step-item.e-step-label .e-step-label-container {
        position: relative;
        &.e-label-before {
          order: -1;
        }
      }
      &.e-label-start .e-step-text-container {
        order: $stepper-label-order;
      }
      .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        position: absolute;
        top: 2.5em;
        margin-left: 2.5em;
      }
      &.e-label-start .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional,
      &.e-rtl .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-right: 2.5em;
        margin-left: unset;
      }
      &.e-label-start.e-rtl .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-right: unset;
        margin-left: 2.5em;
      }
      &.e-label-before .e-stepper-steps {
        &:has(.e-step-label-optional) {
          align-items: flex-end;
        }
        .e-step-label-container.e-label-before:has(.e-step-label-optional) {
          flex-direction: column;
          gap: .5em;
        }
      }
      .e-text,
      .e-label,
      .e-step-label-optional {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .e-step-text.e-step-text-only:has(.e-step-label-optional),
      .e-step-label.e-step-label-only:has(.e-step-label-optional),
      .e-step-item:not(.e-step-text):not(.e-step-label):has(.e-step-label-optional),
      .e-step-container:not(.e-step-item):not(.e-step-text):not(.e-step-label):has(.e-step-label-optional),
      .e-step-label-container.e-label-after:has(.e-step-label-optional),
      &.e-step-type-indicator .e-step-container {
        flex-wrap: wrap;
      }
      .e-step-item:not(.e-step-label):not(.e-step-text) .e-step-label-optional {
        margin-top: .5em;
      }
      &.e-label-after .e-step-item.e-step-label:not(.e-step-text) .e-step-label-optional {
        margin-top: .2em;
      }
    }
    &.e-vertical {
      min-height: inherit;
      display: inline-block;
      .e-step-label.e-step-label-only,
      .e-step-template {
        position: relative;
      }
      &:not(.e-label-top):not(.e-label-bottom) .e-step-label-optional {
        position: absolute;
      }
      &:not(.e-label-bottom):not(.e-label-top) .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-top: $stepper-label-optional;
        margin-left: 4em;
      }
      &.e-rtl:not(.e-label-bottom):not(.e-label-top) .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
        margin-left: unset;
        margin-right: 4em;
      }
      .e-stepper-steps {
        flex-direction: column;
        min-height: inherit;
        float: left;
        align-items: start;
        margin: $stepper-ol-padding;
        justify-content: space-between;
      }
      &.e-rtl .e-stepper-steps {
        float: right;
      }
      .e-stepper-steps:has(.e-step-item:not(.e-step-text):not(.e-step-label)),
      .e-stepper-steps:has(.e-step-text.e-step-text-only),
      .e-stepper-steps:has(.e-step-label.e-step-label-only),
      &.e-label-after .e-stepper-steps:has(.e-step-label.e-step-text:not(.e-step-item)),
      &.e-step-type-indicator .e-stepper-steps {
        align-items: center;
      }
      .e-step-item.e-step-text:not(.e-step-label) .e-step-text-container,
      .e-step-item.e-step-label:not(.e-step-text) .e-step-label-container.e-label-after {
        margin-left: $stepper-text-postion;
      }
      &.e-rtl {
        .e-step-item.e-step-text:not(.e-step-label) .e-step-text-container,
        .e-step-item.e-step-label:not(.e-step-text) .e-step-label-container.e-label-after {
          margin-left: unset;
          margin-right: $stepper-text-postion;
        }
      }
      .e-step-item:not(.e-step-label):not(.e-step-text):has(.e-step-label-optional),
      &.e-label-before .e-step-label-container.e-label-before:has(.e-step-label-optional),
      .e-step-text-only.e-step-text:has(.e-step-label-optional),
      .e-step-label-only.e-step-label:has(.e-step-label-optional) {
        flex-direction: column;
        .e-step-label-optional {
          position: unset;
        }
      }
      .e-step-item:not(.e-step-label):not(.e-step-text):has(.e-step-label-optional),
      &.e-label-before .e-step-label-container.e-label-before:has(.e-step-label-optional) {
        gap: .5em;
      }
      &.e-label-before .e-step-item.e-step-label,
      &.e-label-after .e-step-item.e-step-label {
        .e-step-label-container.e-label-after:has(.e-step-label-optional) {
          flex-direction: column;
          .e-label {
            line-height: 2em;
          }
        }
        .e-step-label-optional {
          position: unset;
        }
      }
      .e-step-container {
        &.e-step-label.e-step-text:not(.e-step-item) .e-step-label-container.e-label-after {
          position: absolute;
          .e-step-label-optional {
            left: $stepper-ol-padding;
          }
        }
        .e-step-label-container.e-label-after .e-step-label-optional {
          font-size: $stepper-optional-size;
        }
        .e-step-label-container.e-label-before .e-label {
          width: var(--label-width);
        }
        &:not(.e-step-text-only) .e-step-text-container {
          border: none;
          border-radius: unset;
          padding: $stepper-text-vert-padding;
        }
        &.e-step-text-only .e-step-text-container,
        &.e-step-label-only .e-step-label-container {
          position: unset;
        }
      }
      &.e-label-top .e-step-label-optional {
        padding-bottom: 6px;
      }
    }
    &.e-vertical.e-label-top,
    &.e-vertical.e-label-bottom {
      .e-stepper-steps {
        align-items: center;
        .e-step-container {
          flex-direction: column;
          .e-step-text-container {
            margin-left: $stepper-ol-padding;
          }
        }
      }
    }
    &.e-vertical.e-label-top .e-step-container {
      .e-step-label-optional,
      .e-step-text-container {
        order: $stepper-label-order;
      }
    }
    .e-stepper-progressbar {
      height: $stepper-progress-bar-height;
      position: absolute;
      top: var(--progress-top-position);
      width: $stepper-progress-value-height;

      >.e-progressbar-value {
        height: $stepper-progress-value-height;
        width: var(--progress-value);
        transition-property: width;
        transition-duration: var(--duration);
        transition-delay: var(--delay);
      }
    }
    &.e-vertical .e-stepper-progressbar {
      min-height: inherit;
      width: $stepper-progress-bar-height;
      top: $stepper-ol-padding;
      left: var(--progress-position);

      >.e-progressbar-value {
        width: $stepper-progress-value-height;
        height: var(--progress-value);
        transition-property: height;
      }
    }
    &.e-vertical.e-rtl .e-stepper-progressbar {
      right: var(--progress-position);
      left: unset;
    }
    &:not(.e-steps-focus):not(.e-step-type-label):not(.e-step-type-indicator) .e-step-container .e-indicator,
    .e-step-focus .e-indicator {
      z-index: 0;
    }
    .e-step-template.e-step-focus {
      outline: $stepper-temp-outline-width;
    }
  }
  .e-tooltip-wrap.e-stepper-tooltip.e-step-inprogress-tip {
    .e-arrow-tip.e-tip-bottom {
      top: 90% !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .e-small.e-stepper,
  .e-small .e-stepper {
    @include stepper-styles($stepper-small-step-width, $stepper-small-icon-size, $stepper-small-text-size, $stepper-small-optional-size);
  }
}
