table.sub-tasks tr.sub-task.completed td input[type='text'],
table.sub-tasks tr.sub-task.completed td .e-editable-value,
table.sub-tasks tr.sub-task.completed td.completed-date {
  @extend .text-more-muted;
  @extend .text-decoration-line-through;
}

table.sub-tasks a {
  color: $gray-400;
}

table.sub-tasks a.active,
table.sub-tasks a:hover {
  color: $primary;
}

table.sub-tasks a.delete:hover {
  color: $danger;
}

table.sub-tasks .reorder {
  @extend .text-more-muted;

  cursor: move;
}

table.sub-tasks input[type='text'] {
  @extend .form-control;
  @extend .w-100;
  @extend .border-0;

  padding: 3px 8px;
}

table.sub-tasks input[type='text'][readonly] {
  @extend .text-muted;

  pointer-events: none;
}
