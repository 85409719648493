@include export-module('richtexteditor-layout') {

  /*! tab layout */

  .e-richtexteditor {
    display: block;
    position: relative;

    .e-rte-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
  
      &.e-count-enabled,
      &.e-resize-enabled {
        .e-content {
          padding-bottom: 0;
        }
  
        .e-rte-content,
        .e-source-content {
          margin-bottom: 20px;
        }
      }
  
      &.e-source-code-enabled {
        .e-rte-content {
          display: none;
        }
  
        .e-source-content {
          display: block;
          overflow: hidden;
        }
      }
    }
  
    .e-rte-content {
      flex-grow: 1;
  
      .rte-placeholder.e-rte-placeholder.enabled {
        display: block;
        font-size: $rte-content-font-size;
      }
    }
  
    .e-source-content {
      display: none;
    }

    & textarea.e-content {
      border: 0;
      display: block;
      height: 100%;
      margin: 0;
      outline: 0;
      padding: 8px;
      resize: none;
      width: 100%;
    }

    & .e-rte-hidden {
      display: none;
    }

    &.e-disabled {
      pointer-events: none;
    }

    &.e-rte-full-screen {
      bottom: 0;
      height: 100% !important; /* stylelint-disable-line declaration-no-important */
      left: 0;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      width: 100% !important; /* stylelint-disable-line declaration-no-important */
      z-index: 999;

      .e-resize-handle {
        display: none;
      }

      iframe {
        height: calc(100% - 42px);
      }

      .e-rte-edit-table {
        max-height: $rte-table-dlg-max-height !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-rte-edit-table-prop-dialog {
        max-height: $rte-edit-table-dlg-max-height !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-rte-link-dialog {
        max-height: $rte-link-dlg-max-height !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-rte-img-dialog {
        max-height: $rte-img-dlg-max-height !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-rte-img-link-dialog {
        max-height: $rte-img-link-dlg-max-height !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-rte-img-size-dialog {
        max-height: $rte-img-size-dlg-max-height !important; /* stylelint-disable-line declaration-no-important */
      }

      .e-rte-img-alt-dialog {
        max-height: $rte-img-alt-dlg-max-height !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    &.e-rtl .e-rte-character-count {
      left: 0;
      padding-left: 30px;
      padding-right: unset;
      right: unset;
    }

    &.e-rtl .e-rte-content .e-content blockquote {
      padding-left: 0;
      padding-right: 12px;
    }

    .e-rte-toolbar {

      @if $skin-name == 'bootstrap5' {
        &.e-toolbar .e-hor-nav {
          min-height: $toolbar-expand-icon-min-height;
        }
      }

      .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child {
        margin-left: $rte-tb-items-margin-left;
      }

      .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-item:first-child {
        margin-left: 0;
      }

      .e-hor-nav.e-expended-nav {
        height: auto;
      }

      .e-toolbar-multirow {
        margin-left: 7px;
        margin-right: 7px;
      }
    }

    &.e-rtl .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child {
      margin-left: 0;
      margin-right: $rte-tb-items-margin-left;
    }

    &.e-rtl .e-rte-toolbar .e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child {
      margin-right: 0;
    }

    .e-rte-toolbar.e-rte-tb-mobile .e-toolbar-multirow {
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
    }

    .e-rte-toolbar,
    .e-rte-toolbar.e-toolbar.e-extended-toolbar {

      .e-toolbar-extended {
        margin-left: 0;
        padding-left: $rte-tb-items-padding-left;
      }
    
      .e-toolbar-items,
      .e-toolbar-extended {

        .e-toolbar-item {

          .e-formats-tbar-btn > :first-child {
            min-width: $rte-format-btn-min-width;
          }

          .e-font-name-tbar-btn > :first-child {
            min-width: $rte-font-name-btn-min-width;
          }

          .e-font-size-tbar-btn > :first-child {
            min-width: $rte-font-size-btn-min-width;
          }

          .e-dropdown-btn {
            padding-left: $rte-drop-btn-padding-left;
            padding-right: $rte-drop-btn-padding-right;

            .e-rte-dropdown-btn-text {
              font-size: $dropdown-btn-font-size;
              font-weight: $font-weight;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .e-dropdown-btn:hover,
          .e-dropdown-btn:focus,
          .e-dropdown-btn:active {
            padding-left: $rte-drop-btn-action-padding-left;
            padding-right: $rte-drop-btn-action-padding-right;
          }

          .e-rte-font-color .e-selected-color.e-icons::before {
            font-size: 12px;
            position: relative;
            top: -18px;
          }

          .e-rte-numberformatlist-dropdown .e-rte-list-primary-content,
          .e-rte-bulletformatlist-dropdown .e-rte-list-primary-content {
            line-height: $rte-formatlists-dropdown-line-height;
            @if $skin-name == 'FluentUI' {
              padding: 5px;
            }
          }

          .e-background-color.e-icons::before {
            display: inline;
            font-size: $rte-background-color-icon-fontsize;
          }

          .e-tbar-btn {

            .e-order-list.e-icons,
            .e-unorder-list.e-icons,
            .e-icons.e-btn-icon:not(.e-caret) {
              font-size: $rte-toolbar-icon-size;
            }
          }

          &.e-active .e-tbar-btn.e-btn {
            padding: $rte-active-tb-item-btn-padding;
            @if $skin-name == 'FluentUI' {
              border-radius: 0;
            }
          }
        }
        .e-dropdown-btn .e-rte-color-content {
          padding-top: $rte-dropdown-btn-color-content-padding;
          height: $rte-dropdown-btn-color-content-height;
        }
      }

      @if $skin-name == 'bootstrap5' {
        .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn {
          padding: 0 4px;
        }
      }

      &.e-control[class *= 'e-toolbar'] {
        box-sizing: border-box;
      }

      .e-toolbar-extended.e-visible {
        display: block;
        visibility: hidden;
      }

      &.e-tbar-ios-fixed.e-rte-tb-fixed {
        top: 0;
        transform: translate3d(0, 0, 0);
      }

      &.e-rte-tb-fixed:not(.e-tbar-ios-fixed) {
        bottom: 0;
      }

      &.e-rte-tb-fixed {
        display: none;
        left: 0;
        position: fixed;
        right: 0;

        &.e-show {
          display: block;
          z-index: 101;
        }
      }
    }

    .rte-placeholder {
      color: $rte-content-font-color;
      line-height: $rte-placeholder-line-height;
      opacity: .54;
      overflow: hidden;
      padding: $rte-placeholder-padding;
      position: absolute;
      text-align: start;
      top: 0;
      z-index: 1;
      display: none;
    }

    .e-rte-content {
      position: relative;
      z-index: 1;
    }

    .e-rte-content,
    .e-source-content  {
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-overflow-scrolling: touch; //iOS devices scrolling smooth
      // sass-lint:enabled no-vendor-prefixes
      overflow: auto;
      transition: 100ms ease-out;
      width: 100%;
      font-size: $rte-content-font-size;

      .e-content {
        width: 100%;
        float: left;
        background: unset;
        box-sizing: border-box;
        height: 100%;
        min-height: 100px;
        outline: 0 solid transparent;
        padding: $rte-content-padding;
        position: relative;
        text-align: inherit;
        z-index: 2;
        font-weight: normal; /* normal = 400 */
        line-height: 1.5;
        font-size: 1em;
      }

      .e-content p {
        margin: 0 0 10px;
        margin-bottom: 10px;
      }

      .e-content code {
        background: $rte-code-background;
        color: $rte-code-color;
      }

      .e-content li {
        margin-bottom: 10px;
      }
      
      .e-content li ol,
      .e-content li ul {
        margin-block-start: 10px;
      }

      .e-content ul {
        list-style-type: disc;
      }

      .e-content ul ul,
      .e-content ol ul {
        list-style-type: circle;
      }

      .e-content ul ul ul,
      .e-content ol ul ul,
      .e-content ul ol ul,
      .e-content ol ol ul {
        list-style-type: square;
      }

      .e-content h1 {
        font-size: 2.857em;
        font-weight: 600;
        line-height: 1.2;
        margin: 10px 0;
      }

      .e-content h2 {
        font-size: 2.285em;
        font-weight: 600;
        line-height: 1.2;
        margin: 10px 0;
      }

      .e-content h3 {
        font-size: 2em;
        font-weight: 600;
        line-height: 1.2;
        margin: 10px 0;
      }

      .e-content h4 {
        font-size: 1.714em;
        font-weight: 600;
        line-height: 1.2;
        margin: 10px 0;
      }

      .e-content h5 {
        font-size: 1.428em;
        font-weight: 600;
        line-height: 1.2;
        margin: 10px 0;
      }

      .e-content h6 {
        font-size: 1.142em;
        line-height: 600;
        line-height: 1.5;
        margin: 10px 0;
      }

      .e-content blockquote {
        margin: 10px 0;
        margin-left: 0;
        padding-left: 12px;
      }
      
      .e-content table {
        margin-bottom: 10px;
        border-collapse: collapse;
        empty-cells: show;
      }

      .e-content pre {
        background-color: $rte-content-pre-background;
        border: 0;
        border-radius: 0;
        color: $rte-content-color;
        font-size: inherit;
        line-height: inherit;
        margin: 0 0 10px;
        overflow: visible;
        padding: 0;
        white-space: pre-wrap;
        word-break: inherit;
        word-wrap: break-word;

        @if $skin-name == 'fluent2' or $theme-name == 'fluent2-highcontrast' or $skin-name == 'fluent2-dark' {
          background-color: $rte-pre-bg-color;
        }
      }

      .e-content strong,
      .e-content b {
        font-weight: bold;
      }

      .e-content a {
        text-decoration: none;
        user-select: auto;
      }

      .e-content a:hover {
        text-decoration: underline;
      }

      .e-content p:last-child,
      .e-content pre:last-child,
      .e-content blockquote:last-child {
        margin-bottom: 0;
      }

      .e-content h3 + h4,
      .e-content h4 + h5,
      .e-content h5 + h6 {
        margin-top: .6em;
      }

      .e-content ul:last-child {
        margin-bottom: 0;
      }

      .e-content table.e-cell-select {
        position: relative;
      }
  
      .e-content table.e-cell-select::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid $rte-table-select-border-color;
        pointer-events: none;
      }
  
      .e-content table td.e-cell-select.e-multi-cells-select,
      .e-content table th.e-cell-select.e-multi-cells-select {
        border: 1px double $rte-table-select-border-color;
        position: relative;
      }
  
      .e-content table td.e-cell-select.e-multi-cells-select::after,
      .e-content table th.e-cell-select.e-multi-cells-select::after {
        background-color: $rte-table-multicell-select-bg-color;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        pointer-events: none;
        right: 0;
      }
      .e-content table td.e-multi-cells-select ::selection,
      .e-content table th.e-multi-cells-select ::selection {
        background-color: transparent;
      }
      .e-content table td.e-multi-cells-select,
      .e-content table th.e-multi-cells-select {
        user-select: none;
      }
    }

    .e-rte-character-count {
      bottom: 0;
      color: $rte-default-character-count-color;
      font-size: 14px;
      margin-right: 30px;
      opacity: $rte-default-character-count-opacity;
      padding-bottom: 2px;
      position: absolute;
      right: 0;
      z-index: 100;
      pointer-events: none;

      &.e-warning {
        color: $rte-warning-character-count-color;
        opacity: unset;
      }

      &.e-error {
        color: $rte-error-character-count-color;
        opacity: unset;
      }
    }

    .e-rte-srctextarea {
      background-color: transparent;
      border: 0;
      color: $rte-content-color;
      height: 100%;
      line-height: 22px;
      overflow: auto;
      padding: 16px;
      resize: none;
      transition: 100ms ease-out;
      width: 100%;
    }

    .e-resize-handle {
      height: $rte-resize-handler-height;
      position: absolute;
      width: $rte-resize-handler-width;

      &.e-south-east {
        bottom: $rte-resize-handler-position;
        cursor: nwse-resize;
        right: $rte-resize-handler-position;
        z-index: 100;
      }

      &.e-south-west {
        bottom: $rte-resize-handler-position;
        cursor: sw-resize;
        left: $rte-resize-handler-position;
        transform: rotate(90deg);
        z-index: 100;
      }
    }

    &.e-rtl {

      &.e-rte-resize {
        float: right;
      }

      .e-resize-handle.e-south-west {
        direction: ltr;
        text-align: initial;
      }
    }

    &.e-rte-tb-expand {
      &.e-rte-fixed-tb-expand {
        transition: none;
      }
    }
    .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon:not(.e-toolbar-pop) {
      padding: $rte-extended-toolbar-items-padding;
    }
    .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover {
      background: $rte-expand-tbar-hover-bg;
    }
    .e-toolbar .e-toolbar-item .e-tbar-btn,
    .e-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop .e-toolbar-item .e-tbar-btn{
      font-size: $rte-tbar-icon-size;
    }
    .e-clickelem::after,
    .e-video-clickelem::after {
      left: 0;
      right: 0;
      z-index: 1;
    }
    .e-clickelem::before,
    .e-video-clickelem::before {
      left: 0;
      right: 0;
      z-index: 1;
    }
    .e-clickelem::before {
      bottom: 70%;
      top: 0;
    }
    .e-clickelem::after {
      bottom: 0;
      top: 70%;
    }
    .e-video-clickelem::before {
      bottom: 85%;
      top: 0;
    }
    .e-video-clickelem::after {
      bottom: 0;
      top: 90%;
    }
    
    .e-audio-wrap,
    .e-embed-video-wrap {
      display: inline-block;
    }

    .e-clickelem,
    .e-video-clickelem {
      display: block;
      margin: 0;
      outline: none;
      padding: 0;
      position: relative;
    }

    .e-clickelem::after,
    .e-video-clickelem::after {
      background: transparent;
      content: '';
      cursor: default;
      display: block;
      position: absolute;
    }

    .e-clickelem::before,
    .e-video-clickelem::before {
      background: transparent;
      content: '';
      cursor: default;
      display: block;
      position: absolute;
    }

    .e-videoheader,
    .e-embed-videoheader,
    .e-video-url-wrap {
      padding-bottom: 5px;
      padding-top: 15px;
    }

    .e-video-url-wrap .e-radio-wrapper {
      padding: 5px;
    }

    .e-video-url-wrap textarea {
      height: 80px;
    }

    .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon.e-caret:not(.e-toolbar-pop,.e-rte-fontcolor-dropdown .e-caret,.e-rte-backgroundcolor-dropdown .e-caret, .e-rte-numberformatlist-dropdown .e-caret,.e-rte-bulletformatlist-dropdown .e-caret){
      padding-left: 9px;
    }
    .e-toolbar-item .e-caret:not(.e-toolbar-pop,.e-rte-fontcolor-dropdown .e-caret,.e-rte-backgroundcolor-dropdown .e-caret, .e-rte-numberformatlist-dropdown .e-caret,.e-rte-bulletformatlist-dropdown .e-caret){
      padding-left: 9px;
    }
  }

  .e-rte-quick-toolbar .e-toolbar-item .e-caret:not(.e-toolbar-pop,.e-rte-fontcolor-dropdown .e-caret,.e-rte-backgroundcolor-dropdown .e-caret, .e-rte-numberformatlist-dropdown .e-caret,.e-rte-bulletformatlist-dropdown .e-caret){
    padding-left: 9px;
  }

  .e-rte-linkcontent .e-rte-label {
    padding-top: $rte-insert-dialog-label-padding-top;
  }

  .e-rte-label label {
    font-weight: normal;
  }

  .e-rte-linkcontent .e-rte-label:first-child {
    padding-top: 0;
  }

  .e-rte-dropdown-popup {

    &.e-rte-inline-dropdown {

      @media screen and (max-width: 768px) {
        ul {
          padding: 7px 0;

          li.e-item {
            height: 34px;
            line-height: 34px;
            padding: 0 6.5px;
          }

          .e-item .e-menu-icon {
            float: none;
          }
        }
      }

      &.e-rte-dropdown-icons.e-dropdown-popup {

        @media screen and (max-width: 768px) {
          max-height: 280px;

          ul {
            display: inline-flex;
          }
        }
      }
    }

    ul {
      min-width: 82px;

      .e-item {

        .e-menu-icon {
          margin: 0 6px;
          width: auto;
        }

        &.e-h1 {
          font-size: 2em;
          font-weight: bold;
          height: 40px;
          @if $skin-name =='fluent2' {
            line-height: 25px;
          }
          @else {
            line-height: 40px;
          }
        }

        &.e-code{
          font-family: monospace;
          unicode-bidi: isolate;
          white-space: pre;
        }

        &.e-h2 {
          font-size: 1.5em;
          font-weight: bold;
          height: 40px;
          @if $skin-name =='fluent2' {
            line-height: 25px;
          }
          @else {
            line-height: 40px;
          }
        }

        &.e-h3 {
          font-size: 1.16em;
          font-weight: bold;
        }

        &.e-h4 {
          font-size: 1em;
          font-weight: bold;
        }

        &.e-h5 {
          font-size: .83em;
          font-weight: bold;
        }

        &.e-h6 {
          font-size: .7em;
          font-weight: bold;
        }

        &.e-segoe-ui {
          font-family: 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
        }

        &.e-arial {
          font-family: 'Arial', 'Helvetica', sans-serif;
        }

        &.e-courier-new {
          font-family: 'Courier New', 'Courier', monospace;
        }

        &.e-georgia {
          font-family: 'Georgia', 'Times New Roman', 'Times', serif;
        }

        &.e-helvetica-neue {
          font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
        }

        &.e-impact {
          font-family: 'Impact', 'Haettenschweiler', 'Arial Narrow Bold', sans-serif;
        }

        &.e-lucida-console {
          font-family: 'Lucida Console', 'Monaco', monospace;
        }

        &.e-tahoma {
          font-family: 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
        }

        &.e-times-new-roman {
          font-family: 'Times New Roman', 'Times', serif;
        }

        &.e-trebuchet-ms {
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', 'Arial', sans-serif;
        }

        &.e-verdana {
          font-family: 'Verdana', 'Geneva', 'Tahoma', sans-serif;
        }

        &.e-roboto {
          font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif;
        }
      }
    }

    &.e-rte-dropdown-icons {

      ul {
        min-width: auto;
      }

      @media screen and (max-width: 768px) {

        ul {
          min-width: 30px;
        }
      }
    }

    &.e-rtl {
      ul .e-item .e-menu-icon {
        margin: 0 6px;
      }
    }
  }

  .e-rte-image.e-imgbreak,
  .e-rte-audio.e-audio-break,
  .e-rte-video.e-video-break {
    border: 0;
    cursor: pointer;
    display: block;
    float: none;
    max-width: 100%;
    padding: 1px;
    position: relative;
  }

  .e-rte-image,
  .e-rte-audio,
  .e-rte-video  {
    border: 0;
    cursor: pointer;
    display: block;
    float: none;
    margin: auto;
    max-width: 100%;
    position: relative;
  }

  .e-rte-image.e-imginline,
  .e-rte-audio.e-audio-inline,
  .e-rte-video.e-video-inline {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    float: none;
    max-width: 100%;
    padding: 1px;
    vertical-align: bottom;
  }

  .e-rte-image.e-imgcenter,
  .e-rte-video.e-video-center {
    cursor: pointer;
    display: block;
    float: none;
    margin: 5px auto;
    max-width: 100%;
    position: relative;
  }

  .e-rte-image.e-imgleft,
  .e-rte-video.e-video-left {
    float: left;
    margin: 0 auto;
    margin-right: 5px;
    text-align: left;
  }

  .e-rte-image.e-imgright,
  .e-rte-video.e-video-right {
    float: right;
    margin: 0 auto;
    margin-left: 5px;
    text-align: right;
  }

  .e-rte-img-popup .e-item {
    height: 106px;
    width: 300px;
  }

  .e-rte-img-caption {
    display: inline-block;
    margin: 5px auto;
    max-width: 100%;
    position: relative;
  }

  .e-rte-img-caption.e-caption-inline {
    display: inline-block;
    margin: 5px auto;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
    position: relative;
    text-align: center;
    vertical-align: bottom;
  }

  .e-rte-img-caption.e-imgcenter {
    display: contents;
    margin-left: auto;
    margin-right: auto;
  }

  .e-rte-img-caption.e-imgright {
    display: contents;
    margin-left: auto;
    margin-right: 0;
  }

  .e-rte-img-caption.e-imgleft {
    display: contents;
    margin-left: 0;
    margin-right: auto;
  }

  .e-rte-img-caption .e-rte-image.e-imgright {
    margin-left: auto;
    margin-right: 0;
  }

  .e-rte-img-caption .e-rte-image.e-imgleft {
    margin: 0;
  }

  .e-img-caption.e-rte-img-caption.e-imgbreak {
    display: contents;
  }

  .e-rte-table td,
  .e-rte-table th {
    border: 1px solid $rte-table-border-color;
    height: 20px;
    min-width: 20px;
    padding: 2px 5px;
    box-sizing: border-box;
  }

  .e-rte-table.e-dashed-border td,
  .e-rte-table.e-dashed-border th {
    border-style: dashed;
  }

  .e-rte-img-caption .e-img-inner {
    box-sizing: border-box;
    display: block;
    font-size: $rte-toolbar-icon-size;
    font-weight: initial;
    margin: auto;
    opacity: .9;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .e-rte-img-caption.e-imgleft .e-img-inner {
    float: left;
    text-align: left;
  }

  .e-rte-img-caption.e-imgright .e-img-inner {
    float: right;
    text-align: right;
  }

  .e-rte-img-caption.e-imgleft .e-img-wrap,
  .e-rte-img-caption.e-imgright .e-img-wrap {
    display: contents;
  }

  .e-rte-img-caption .e-img-wrap {
    display: inline-block;
    margin: auto;
    padding: 0;
    width: 100%;
  }

  .e-rte-img-dialog .e-rte-label {
    padding-top: $rte-insert-dialog-label-padding-top;
  }

  .e-rte-img-dialog .e-rte-label:first-child {
    padding-top: 0;
  }

  .e-rte-video-dialog .e-rte-label {
    padding-top: $rte-insert-dialog-label-padding-top;
  }

  .e-rte-video-dialog .e-rte-label:first-child {
    padding-top: 0;
  }

  .e-rte-table-resize.e-row-resize,
  .e-rte-table-resize.e-column-resize {
    background-color: transparent;
    background-repeat: repeat;
    bottom: 0;
    cursor: col-resize;
    height: 1px;
    overflow: visible;
    position: absolute;
    width: 1px;
  }

  .e-rte-table-resize.e-row-resize {
    cursor: row-resize;
    height: 1px;
  }

  .e-richtexteditor,
  .e-rte-elements {
    .e-linkheader,
    .e-audioheader,
    .e-videoheader,
    .e-video-url-wrap {
      font-family: $rte-font-family;
      font-size: $rte-insert-dialog-font-size;
      opacity: .87;
      padding-bottom: $rte-insert-dialog-label-padding-bottom;
      padding-top: $rte-insert-dialog-label-padding-top;
    }

    .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
    .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control {
      padding: $rte-toolbar-expaned-padding;
    }

    .e-toolbar .e-toolbar-pop .e-toolbar-item.e-tbtn-align .e-btn.e-control .e-icons.e-btn-icon {
      min-width: $rte-toolbar-expaned-minwidth;
    }

    .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover {
      padding: $rte-toolbar-expaned-padding-hover;
    }

    .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-delete-btn.e-icons,
    .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons,
    .e-aud-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-delete-btn.e-icons,
    .e-aud-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons,
    .e-vid-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-delete-btn.e-icons,
    .e-vid-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons  {
      margin-top: 0;
    }

    .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons,
    .e-aud-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons,
    .e-vid-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons {
      margin-right: 30px;
    }

    .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list,
    .e-aud-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list,
    .e-vid-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list  {
      border-bottom: 0 solid transparent;
      min-height: initial;
    }

    .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list .e-file-container,
    .e-aud-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list .e-file-container,
    .e-vid-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list .e-file-container {
      margin-left: 0;
      margin-right: 0;
    }

    .e-img-uploadwrap.e-droparea .e-upload .e-upload-files,
    .e-aud-uploadwrap.e-droparea .e-upload .e-upload-files,
    .e-vid-uploadwrap.e-droparea .e-upload .e-upload-files {
      border-top: 0 solid transparent;
    }

    .e-img-uploadwrap.e-droparea .e-file-select-wrap,
    .e-aud-uploadwrap.e-droparea .e-file-select-wrap,
    .e-vid-uploadwrap.e-droparea .e-file-select-wrap {
      display: none;
    }

    .e-img-uploadwrap.e-droparea .e-upload,
    .e-aud-uploadwrap.e-droparea .e-upload,
    .e-vid-uploadwrap.e-droparea .e-upload {
      border: 0 solid transparent;
      float: none;
    }

    .e-dialog .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons {
      margin-right: $rte-img-upload-abort-icon-btn-margin-right;
      margin-top: $rte-img-upload-abort-icon-btn-margin-top;
    }

    .e-dialog .e-img-uploadwrap.e-droparea .e-browsebtn,
    .e-dialog .e-aud-uploadwrap.e-droparea .e-browsebtn,
    .e-dialog .e-vid-uploadwrap.e-droparea .e-browsebtn {
      display: block;
      height: $rte-droparea-browsebtn-height;
      margin: 0 auto 10px;
      padding: $rte-droparea-browsebtn-padding;
      position: relative;
      top: 6px;
      @if $skin-name == 'FluentUI' {
        outline: none;
      }
    }

    .e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-browsebtn,
    .e-dialog.e-device.e-dlg-modal .e-aud-uploadwrap.e-droparea .e-browsebtn,
    .e-dialog.e-device.e-dlg-modal .e-vid-uploadwrap.e-droparea .e-browsebtn {
      display: none;
    }

    .e-dialog .e-img-uploadwrap.e-droparea,
    .e-dialog .e-aud-uploadwrap.e-droparea,
    .e-dialog .e-vid-uploadwrap.e-droparea {
      line-height: $rte-droparea-line-height;
      min-height: 50px;
      position: relative;
    }

    .e-dialog .e-img-uploadwrap.e-droparea .e-rte-upload-text,
    .e-dialog .e-aud-uploadwrap.e-droparea .e-rte-upload-text,
    .e-dialog .e-vid-uploadwrap.e-droparea .e-rte-upload-text {
      display: inline-block;
      line-height: normal;
    }

    .e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea,
    .e-dialog.e-device.e-dlg-modal .e-aud-uploadwrap.e-droparea,
    .e-dialog.e-device.e-dlg-modal .e-vid-uploadwrap.e-droparea {
      line-height: 4;
    }

    .e-rte-inline-dropdown ul {
      max-height: 200px;
      overflow-y: auto;
    }

    .e-rte-dropdown-popup.e-rte-dropdown-items ul {
      max-height: 280px;
      overflow-y: auto;
    }

    .e-dropdown-btn.e-rte-backgroundcolor-dropdown,
    .e-dropdown-btn.e-rte-fontcolor-dropdown,
    .e-dropdown-btn.e-rte-numberformatlist-dropdown,
    .e-dropdown-btn.e-rte-bulletformatlist-dropdown {
      line-height: $rte-inline-dropdown-line-height;
    }

    .e-rte-table-popup.e-popup.e-popup-open {
      border-radius: $rte-table-popup-bdr-radius;
      box-shadow: $rte-table-popup-box;
      font-size: 14px;
      font-weight: normal;
      min-width: 120px;
      overflow: hidden;
      padding: $rte-table-popup-padding;
      border: $rte-table-popup-border;
    }

    .e-rte-table-popup.e-popup-open .e-rte-tablecell {
      border: $rte-table-span-border;
      display: inline-block;
      height: $rte-table-popup-tablecell-height;
      margin: $rte-table-popup-tablecell-margin;
      overflow: hidden;
      vertical-align: top;
      width: $rte-table-popup-tablecell-width;
    }

    .e-rte-table-popup.e-popup-open .e-rte-table-row {
      height: $rte-table-popup-row-height;
    }

    .e-rte-table-popup.e-popup-open .e-insert-table-btn {
      width: 100%;
    }

    .e-rte-edit-table .e-rte-field {
      padding-top: $rte-insert-dialog-label-padding-top;
    }

    .e-rte-edit-table .e-rte-field:first-child {
      padding-top: 0;
    }

    .e-table-fake-selection {
      visibility: hidden;
      content: '\u200B';
      position: fixed;
      top: 0;
      left: -9999px;
    }

    .e-rte-content .e-content table td.e-cell-select,
    .e-rte-content .e-content table th.e-cell-select {
      border: 1px double $rte-table-select-border-color;
      height: inherit;
    }

    span.e-table-box {
      cursor: nwse-resize;
      display: block;
      height: 10px;
      position: absolute;
      width: 10px;

      &.e-hide {
        display: none;
      }
    }

    span.e-table-box.e-rmob {
      height: 14px;
      width: 14px;
    }

    .e-upload .e-upload-files .e-upload-file-list .e-file-container {
      margin-right: 50px;
    }

    .e-rte-upload-popup {
      width: 250px;
    }

    .e-rte-dialog-upload .e-upload-files {
      border-top: 0;
    }

    .e-rte-dialog-upload .e-upload-files .e-upload-file-list {
      border-bottom: 0;
    }

    .e-rte-emojipicker-popup.e-popup.e-popup-open {
      border: $rte-emoji-pop-border;
      min-width: 120px;
      height: 330px;
      width: 308px;

      .e-toolbar .e-toolbar-item .e-tbar-btn {
        padding: 7px 3px;
        border-radius: 4px;
        
        @if $skin-name =='bootstrap' or $skin-name =='botstrap-dark' {
          &:hover,
          &:focus{
            padding: 7px 3px;
          }
        }

        @if $skin-name =='material' or $skin-name =='material-dark' {
          padding: 0 6px;
        }

        .e-tbar-btn-text {
          @if $skin-name =='fluent2' {
            font-size: 16px;
          }
          @else {
            font-size: 24px;
          }
          padding: 0;
        }
      }

      .e-input-group.e-control-wrapper {
        margin: 10px;
        width: 94%;

        .e-rte-emoji-search {
          @if $skin-name =='material' or $skin-name =='material-dark' or $skin-name =='material3' or $skin-name =='material3-dark' {
            text-indent: 4px;
          }
        }
      }
      
      .e-rte-emojipicker-btn {
        display: block;
        gap: 10px;
        height: $rte-emoji-btn-height;
        overflow: auto;
        padding: 10px;

        .e-rte-emojipicker-group .e-rte-emojipickerbtn-group {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          gap: 6px;

          .e-btn.e-control {
            display: inline-block;
            box-shadow: none;
            padding: 6px 0;
            width: $rte-emoji-grp-btn-width;
            height: $rte-emoji-grp-btn-height;
            border: none;
            line-height: $rte-emoji-grp-btn-line-height;
            @if $skin-name =='fluent2' {
              font-size: 16px;
            }
            @else {
              font-size: 24px;
            }
          }
        }

        .e-rte-emojisearch-btn {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          gap: 6px;
          height: 0;

          .e-btn.e-control {
            display: inline-block;
            box-shadow: none;
            font-size: 24px;
            padding: 6px 0;
            width: 40px;
            height: 40px;
            border: none;
            line-height: $rte-emoji-grp-btn-line-height;
          }
        }

        .e-rte-emojipicker-group .e-rte-emojipicker-name {
          font-weight: $rte-emoji-headname-font-weight;
          font-size: 14px;
        }

        .e-rte-emojiSearch-noEmoji {
          text-align: center;
        }
      }
    }
  }

  .e-rte-backgroundcolor-colorpicker,
  .e-rte-fontcolor-colorpicker {
    & .e-color-palette.e-container {
      & .e-custom-palette .e-palette {
        padding: 0;
      }

      & .e-switch-ctrl-btn {
        padding: 5px;
      }
    }
  }

  .e-rte-quick-popup {
    border-radius: 2px;
    overflow: hidden;

    .e-rte-quick-toolbar {
      border-radius: 2px;
      min-height: 42px;

      .e-toolbar-items:not(.e-tbar-pos).e-toolbar-multirow {
        white-space: nowrap;
        display: inline-block;
      }

      &.e-remove-white-space {

        .e-toolbar-items:not(.e-tbar-pos).e-toolbar-multirow {
          white-space: nowrap;
        }
      }

      .e-toolbar-items:not(.e-tbar-pos) {
        border-radius: 2px;
        margin: $rte-quick-toolbar-item-margin;
        min-height: 42px;

        .e-toolbar-item.e-rte-horizontal-separator,
        .e-toolbar-item.e-separator.e-multirow-separator.e-rte-horizontal-separator {
          display: block;
          height: 1px;
          margin: 0;
          min-height: 1px;
        }

        .e-toolbar-item {
          margin: $rte-toolbar-item-frist-last-child-margin;
          min-height: 42px;

          &:first-child,
          &:last-child {
            margin: 0;
          }

          .e-tbar-btn:not(.e-rte-dropdown-btn) {
            line-height: $rte-qt-item-btn-line-height;
          }

          .e-tbar-btn:not(.e-rte-dropdown-btn) .e-icons {
            min-width: $rte-quick-item-icon-min-width;
          }
        }

        .e-toolbar-item .e-btn,
        .e-toolbar-item .e-btn:hover {
          min-height: $rte-quick-item-btn-height;
          min-width: $rte-quick-item-btn-width;
          padding: $rte-quick-item-padding;
          @if $skin-name == 'FluentUI' {
            padding-left: $rte-quick-item-padding-left;
            padding-right: $rte-quick-item-padding-right;
          }
        }
      }

      .e-tbar-btn {
        .e-icons.e-btn-icon:not(.e-caret) {
          font-size: $rte-toolbar-icon-size;
        }
      }

      .e-background-color.e-icons::before {
        display: inline;
      }

      .e-rte-fontcolor-dropdown .e-btn-icon.e-caret,
      .e-rte-backgroundcolor-dropdown .e-btn-icon.e-caret,
      .e-rte-numberformatlist-dropdown .e-btn-icon.e-caret,
      .e-rte-bulletformatlist-dropdown .e-btn-icon.e-caret {
        font-size: 10px;
      }
      &.e-rte-toolbar.e-toolbar {
        box-sizing: border-box;
      }
    }

    .e-rte-dropdown-btn.e-tbar-btn {
      line-height: $rte-quick-drop-btn-line-height;
      margin: $rte-quick-drop-btn-margin;
      padding: $rte-quick-drop-btn-padding;

      .e-rte-dropdown-btn-text {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .e-order-list.e-icons,
      .e-unorder-list.e-icons,
      .e-icons:not(.e-caret) {
        font-size: 14px;
      }

      .e-caret {
        font-size: $rte-quick-drop-btn-caret-font-size;
        width: 12px;
      }
    }

    &.e-hide {
      display: block;
      visibility: hidden;
    }
  }

  .e-rte-quick-popup.e-rte-inline-popup {
    .e-rte-quick-toolbar {
      .e-toolbar-item {
        &.e-rte-inline-template {
          min-width: $rte-inline-tmp-min-width;
        }

        &.e-rte-inline-size-template {
          min-width: $rte-inline-tmp-size-min-width;
        }

        &.e-rte-inline-color-template {
          min-width: $rte-inline-tmp-color-min-width;
        }
      }
    }
  }

  .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item {
    button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
    button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
    button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
    button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control {
      @if $skin-name == 'FluentUI' {
        .e-btn-icon.e-icons.e-caret {
          padding-top: 0;
        }
      }
    }
  }

  .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item,
  .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item,
  .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item,
  .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item {

    .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
    .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
    .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
    .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control {
      display: flex;
      padding-left: $rte-colorpicker-parent-padding;
      padding-right: $rte-colorpicker-parent-padding;
      @if $skin-name == 'FluentUI' {
        padding-top: $rte-colorpicker-parent-padding-top;
      }

      &:hover,
      &:active,
      &.e-active,
      &.e-active:hover {
        padding-left: $rte-colorpicker-parent-padding-hover;
        padding-right: $rte-colorpicker-parent-padding-hover;
        @if $skin-name == 'FluentUI' {
          padding-top: $rte-colorpicker-parent-padding-hover-top;
        }
      }

      .e-rte-color-content,
      .e-rte-list-primary-content {
        position: relative;
        vertical-align: middle;
        width: $rte-font-icon-width;
      }

      .e-rte-color-content {
        .e-rte-elements {
          border-bottom-style: solid;
          border-bottom-width: 3px;
          padding-bottom: 1px;
        }
      }

      .e-rte-list-primary-content .e-order-list,
      &.e-active .e-rte-list-primary-content .e-order-list,
      .e-rte-list-primary-content .e-unorder-list,
      &.e-active .e-rte-list-primary-content .e-unorder-list {
        line-height: $rte-split-btn-active-color-icon-line-height;
      }

      .e-rte-color-content::after {
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        width: $rte-split-btn-bar-size;
      }

      .e-icons.e-btn-icon {
        display: flex;
        flex-direction: $rte-list-btn-flex;
        justify-content: center;
        line-height: $rte-list-btn-line-height;
        min-width: $rte-font-arrow-width;
        text-align: center;
        width: $rte-font-arrow-width;
      }

      @if $skin-name == 'bootstrap5' {

        .e-icons.e-btn-icon.e-caret:not(.e-toolbar-pop) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .e-dropdown-btn.e-tbar-btn .e-icons.e-btn-icon.e-caret {
      font-size: $rte-dropdown-caret-icon-size;

      @if $skin-name == 'bootstrap5' {
        &:not(.e-toolbar-pop) {
          font-size: 12px;
        }
      }
    }
    @if $skin-name == 'bootstrap5' {
      .e-dropdown-btn.e-alignment-tbar-btn .e-btn-icon.e-caret {
        min-width: $rte-align-caret-icon-min-width;
      }
    }

    @if $skin-name == 'FluentUI' {
      .e-dropdown-btn:focus,
      .e-dropdown-btn.e-btn:focus {
        box-shadow: none;
      }
    }
  }

  .e-rte-inline-dropdown .e-rte-color-content .e-rte-elements {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    line-height: $rte-font-icon-line-height;
    padding-bottom: 1px;
  }

  .e-popup-modal.e-popup.e-popup-open {
    display: inline-flex;
  }

  .e-rte-modal-popup.e-popup-container.e-center {
    justify-content: center;
  }

  .e-rte-modal-popup.e-popup-container {
    align-items: center;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
  }

  .e-popup-overlay {
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .e-table-rhelper {
    cursor: col-resize;
    opacity: .87;
    position: absolute;
  }

  .e-table-rhelper.e-column-helper {
    width: 1px;
  }

  .e-table-rhelper.e-row-helper {
    height: 1px;
  }

  .e-reicon::before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid;
    border-top: 6px solid transparent;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 20px;
  }

  .e-reicon::after {
    border-bottom: 6px solid transparent;
    border-left: 6px solid;
    border-top: 6px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 20px;
    z-index: 3;
  }

  .e-row-helper.e-reicon::after {
    top: 10px;
    transform: rotate(90deg);
  }

  .e-row-helper.e-reicon::before {
    left: 4px;
    top: -20px;
    transform: rotate(90deg);
  }

  .e-rte-overflow {
    overflow: hidden;
  }

  .e-dialog.e-rte-dialog-minheight {
    min-height: 296px;
    @if $skin-name == 'FluentUI' or $skin-name =='fluent2' {
      min-height: 308px;
    }
  }

  .e-content-placeholder.e-richtexteditor.e-placeholder-richtexteditor {
    background-size: 700px 190px;
    min-height: 190px;
  }

  .e-rte-table.e-rte-table-border {
    border: 1px solid $rte-table-border-color;
    border-collapse: separate;
  }

  .e-rte-img-dialog.e-dialog.e-device.e-dlg-modal,
  .e-rte-audio-dialog.e-dialog.e-device.e-dlg-modal,
  .e-rte-video-dialog.e-dialog.e-device.e-dlg-modal {
    .e-img-uploadwrap.e-droparea,
    .e-aud-uploadwrap.e-droparea,
    .e-vid-uploadwrap.e-droparea {
      line-height: 10;
      min-height: 50px;
      position: relative;

      .e-browsebtn {
        display: block;
        height: 36px;
        margin: 0 auto 10px;
        padding: 0 18px;
        position: relative;
        top: -50px;
        @if $skin-name == 'FluentUI' {
          outline: none;
        }
      }

      .e-upload {
        border: 0 solid transparent;
        float: none;
      }

      .e-file-select-wrap {
        display: none;
      }
    }

    .e-linkheader {
      font-family: $rte-font-family;
      font-size: $rte-big-insert-dialog-font-size;
      opacity: .87;
      padding-bottom: $rte-insert-dialog-label-padding-bottom;
      padding-top: $rte-insert-dialog-label-padding-top;
    }
  }

  .e-rte-file-manager-dialog {

    .e-rte-label {
      padding-bottom: 8px;
      padding-top: 15px;

      label {
        font-size: 15px;
        opacity: .87;
      }
    }
  }

  .e-dialog .e-img-uploadwrap .e-droptext,
  .e-dialog .e-aud-uploadwrap .e-droptext,
  .e-dialog .e-vid-uploadwrap .e-droptext,
  .e-dialog.e-device .e-vid-uploadwrap .e-droptext  {
    border: dashed 2px $rte-upload-drag-border-clr;
    border-radius: 4px;
    color: $rte-drop-text-clr;
    font-family: $rte-font-family;
    font-size: 14px;
    height: $rte-drop-text-height;
    margin: 0 auto;
    text-align: center;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .e-dialog.e-device.e-dlg-modal .e-img-uploadwrap .e-droptext,
  .e-dialog.e-device.e-dlg-modal .e-vid-uploadwrap .e-droptext,
  .e-dialog.e-device.e-dlg-modal .e-aud-uploadwrap .e-droptext {
    margin: 0 auto;
    width: $rte-drop-text-mobile-width;
  }
  .e-dialog.e-device .e-video-url-wrap .e-radio-wrapper {
    padding: 8px 4px;
  }

  .e-rte-text-popup .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn,
  .e-rte-inline-popup .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn {
    .e-rte-dropdown-btn-text,
    .e-background-color,
    .e-rte-font-color {
      @if $skin-name =='FluentUI' or $skin-name =='tailwind' {
        font-size: 16px;
      }
      @else {
        font-size: 14px;
      }
    }
  }

  // Blazor styles start
  .e-rte-upload-popup.e-dialog .e-file-select-wrap {
    display: none;
  }

  .e-rte-upload-popup.e-dialog .e-dlg-content {
    overflow: hidden;
    padding: 0;
  }

  .e-hide.e-rte-quick-popup-hide {
    border: 0;
    position: absolute;
  }

  .e-rte-popup-hide {
    display: none;
  }

  .e-rte-hide-visible {
    visibility: hidden;
  }

  .e-rte-table-popup.e-dialog .e-dlg-content {
    padding: 0;
    @if $skin-name == 'FluentUI' {
      margin-bottom: 0;
    }
  }

  @if $skin-name == 'bootstrap' or $skin-name == 'bootstrap-dark' or $skin-name == 'FluentUI' {
    .e-rte-quick-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
    .e-rte-quick-toolbar.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
    .e-rte-quick-toolbar.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
    .e-rte-quick-toolbar.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .e-richtexteditor .e-rte-table-popup.e-popup-open.e-dialog .e-rte-tablecell,
  .e-rte-elements.e-rte-table-popup.e-popup-open.e-dialog .e-rte-tablecell {
    @if $skin-name == 'fluent2' {
      margin: 0;
    }
    @else {
      margin: 1px;
    }
  }
  
  .e-rte-elements{
    .e-rte-table-popup.e-popup-open{
      .e-rte-table-row {
        height: 16px;
      }
      .e-insert-table-btn {
        @if $skin-name == 'FluentUI' {
          margin-top: $rte-table-popup-btn-margin-top;
        }
        width: 100%;
      }
      .e-rte-tablecell {
        border: $rte-table-span-border;
        display: inline-block;
        height: 14px;
        margin: 1px;
        overflow: hidden;
        vertical-align: top;
        width: 14px;
      }
    }
    .e-rte-table-popup.e-popup.e-popup-open {
      border-radius: $rte-table-popup-bdr-radius;
      box-shadow: $rte-table-popup-box;
      font-size: 14px;
      font-weight: normal;
      min-width: 120px;
      overflow: hidden;
      padding: $rte-table-popup-padding;
    }
    &.e-dropdown-popup.e-popup.e-font-size-tbar-btn.e-popup-open {
      width: 95px;
    }
  }
  
  .e-rte-elements.e-dialog {
    .e-img-uploadwrap.e-droparea .e-rte-upload-text,
    .e-aud-uploadwrap.e-droparea .e-rte-upload-text,
    .e-vid-uploadwrap.e-droparea .e-rte-upload-text {
      display: inline-block;
      line-height: normal;
    }
    .e-img-uploadwrap.e-droparea,
    .e-aud-uploadwrap.e-droparea,
    .e-vid-uploadwrap.e-droparea {
      line-height: $rte-droparea-line-height;
      min-height: 50px;
      position: relative;
    }
    .e-img-uploadwrap.e-droparea .e-browsebtn,
    .e-aud-uploadwrap.e-droparea .e-browsebtn,
    .e-vid-uploadwrap.e-droparea .e-browsebtn {
      display: block;
      height: $rte-droparea-browsebtn-height;
      margin: 0 auto 10px;
      padding: $rte-droparea-browsebtn-padding;
      position: relative;
      @if $skin-name == 'FluentUI' {
        outline: none;
      }
    }
  }
  .e-transparent.e-rte-elements.e-tbar-btn.e-rte-fontcolor-dropdown,
  .e-transparent.e-rte-elements.e-tbar-btn.e-rte-backgroundcolor-dropdown {
    visibility: hidden;
  }
  // Blazor styles end

  .e-richtexteditor {
    .e-toolbar-wrapper.e-rte-tb-float,
    .e-toolbar-container.e-rte-tb-float {
      position: sticky;
      top: 0;
      overflow: visible;
    }
    .e-toolbar-wrapper,
    .e-toolbar-container {
      height: auto;
      z-index: 10;
      white-space: nowrap;
    }
    .e-toolbar .e-toolbar-pop.e-toolbar-extended {
      position: relative;
      width: 100%;
      top: 0 !important; /* stylelint-disable-line declaration-no-important */
      left: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
    .e-toolbar .e-toolbar-pop.e-toolbar-extended.e-popup-open {
      display: block;
    }
  }
  // Used to hide the Color picker during the rendering at the initial stage. Removed the class in the created event.
  .e-rte-picker-init {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  @if $skin-name == 'tailwind' {
    .e-rte-table-popup.e-popup.e-popup-open {
      box-shadow: $rte-table-popup-box;
    }
    .e-rte-elements.e-rte-quick-popup .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
      min-width: 24px;
    }
    .e-richtexteditor .e-toolbar .e-hor-nav{
      min-height: 39px;
    }

    .e-richtexteditor {
      .e-toolbar.e-extended-toolbar .e-toolbar-extended {
        .e-toolbar-item.e-active .e-tbar-btn {
          border: $rte-tb-item-active-border;
        }
      }
    }
  }

  @if $skin-name == 'fabric-dark' {
    .e-richtexteditor {
      .e-toolbar .e-hor-nav{
        min-height: 42px;
      }
    }
  }

  @if $skin-name == 'bootstrap-dark'{
    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover{
      border: 1px solid $grey-white;
    }
  
    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn{
      border: 1px solid transparent;
    }
  }

  @if $skin-name == 'bootstrap5' {
    .e-rte-elements.e-rte-quick-popup .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
      min-width: 24px;
    }

    .e-richtexteditor .e-toolbar .e-insert-table-btn.e-btn .e-icons {
      padding-bottom: 0;
    }
  }

  @if $skin-name == 'Material3' {
    .e-richtexteditor .e-rte-table-popup .e-insert-table-btn {
      font-weight: $font-weight;
      border: 1px solid rgba($info-outline-border);
      border-radius: 20px;
    }
    .e-dialog .e-dlg-header-content + .e-dlg-content{
      padding-top: 0;
    }
    .e-rte-link-dialog .e-dlg-content{
      padding: 7px 20px;
    }
    .e-toolbar-wrapper .e-toolbar .e-toolbar-item:not(.e-separator) {
      padding: 8px 4px;
    }
    .e-rte-quick-popup .e-rte-toolbar.e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
    .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
      margin: 0;
    }
    .e-rte-text-popup .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn,
    .e-rte-inline-popup .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn {
      .e-rte-dropdown-btn-text,
      .e-background-color,
      .e-rte-font-color {
        font-size: 16px;
      }
    }
    .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn {
      .e-background-color,
      .e-rte-font-color{
        font-size: 16px;
      }
    }
  }

  @if $skin-name == 'fluent2' {
    .e-richtexteditor {
      .e-toolbar {
        border: 0;
      }
      .e-toolbar .e-hor-nav{
        min-height: 40px;
      }
      .e-toolbar .e-toolbar-pop {
        border: 0;
      }
      .e-dialog {
        .e-radio + label {
          margin: 0;
        }
      }
    }
    .e-rte-elements .e-toolbar .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
      min-width: 16px;
    }
    .e-rte-container{
      border-radius: 4px;
    }
    .e-richtexteditor{
      border-radius: 4px;
      .e-toolbar {
        border-radius: 4px 4px 0 0;
      }
      .e-toolbar .e-toolbar-items {
        border-radius: 4px 4px 0 0;
      }
      .e-toolbar .e-hor-nav {
        border-radius: 0 4px 0 0;
      }
      .e-rte-content {
        border-radius: 0 0 4px 4px;
      }
    }
    .e-richtexteditor .e-toolbar-wrapper{
      border-radius: 4px 4px 0 0;
    }
  }

  /* Slash Menu Styles */
  .e-rte-elements.e-slash-menu {
    .e-rte-quotes::before {
      content: open-quote;
      font-size: 30px;
      line-height: 20px;
    }
    
    .e-rte-paragraph::before {
      content: 'T';
      font-size: 15px;
    }
    
    .e-rte-h1::before {
      content: 'H1';
      font-size: 13px;
    }
    
    .e-rte-h2::before {
      content: 'H2';
      font-size: 13px;
    }
    
    .e-rte-h3::before {
      content: 'H3';
      font-size: 13px;
    }
    
    .e-rte-h4::before {
      content: 'H4';
      font-size: 13px;
    }
    
    .e-rte-slash-menu-item-text {
      font-size: 14px;
      display: block;
      line-height: 20px;
      padding: 5px 0 1px;
    }
    
    .e-rte-slash-menu-item-description {
      display: block;
      line-height: 20px;
      font-size: 11px;
      padding: 1px 0 5px;
    }
    
    .e-slash-menu-icon {
      line-height: 0;
      display: inline-block;
      width: 50px;
      font-family: 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
    }
  }
}
