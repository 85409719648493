.card {
  @extend .mb-4;
}

.card .table {
  @extend .mb-0;
}

.card .table th,
.card .table td {
  @extend .align-middle;
}

.card .table td .read-only {
  @extend .text-muted;

  padding: 3px 8px;
}

.card .table tr:last-child th:first-child,
.card .table tr:last-child td:first-child {
  border-bottom-left-radius: $border-radius;
}

.card .table tr:last-child th:last-child,
.card .table tr:last-child td:last-child {
  border-bottom-right-radius: $border-radius;
}

@include media-breakpoint-up(md) {
  .card .table > tbody > tr > th:first-child {
    width: 200px;
  }

  .card .table > tbody > tr > th:first-child,
  .card .table > tbody > tr > td:first-child {
    padding-left: 16px;
  }

  .card .table > tbody > tr > th:last-child,
  .card .table > tbody > tr > td:last-child {
    padding-right: 16px;
  }

  .card .table tr:last-child th,
  .card .table tr:last-child td {
    border: none;
  }
}

@include media-breakpoint-down(md) {
  .card .table tr:last-child td:last-child {
    border: none;
    border-radius: $border-radius;
  }

  .card .table:not(.sub-tasks) td:empty {
    display: none;
  }

  .card .table:not(.sub-tasks) th,
  .card .table:not(.sub-tasks) td {
    display: block;
    width: 100% !important;
    text-align: left !important;
  }

  .card .table:not(.sub-tasks) th {
    padding-left: 16px;
    padding-right: 16px;
  }

  .card .table:not(.sub-tasks) td:has(.btn) {
    padding-left: 16px;
  }
}
