@include export-module('toast-material-icons') {
  .e-toast {
    .e-toast-close-icon::before {
      content: '\e7e7';
      font-family: 'e-icons';
    }

    .e-toast-success-icon::before {
      content: '\e727';
      font-family: 'e-icons';
    }

    .e-toast-error-icon::before {
      content: '\e878';
      font-family: 'e-icons';
    }

    .e-toast-info-icon::before {
      content: '\e800';
      font-family: 'e-icons';
    }

    .e-toast-warning-icon::before {
      content: '\e88b';
      font-family: 'e-icons';
    }
  }
}
