html {
  height: 100%;
}

.brand-gradient {
  background: -webkit-linear-gradient(left, #00b5e0, #01cbcd, #01e0ba);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Prevent Help Scout Beacon FAB from animating on every page load
.BeaconFabButtonFrame {
  transform: none !important;
}

#content {
  @extend .container;

  padding-top: 20px;
}

.nav.nav-pills {
  @extend .mb-4;
}

strong {
  font-weight: 600;
}

.fs-7 {
  font-size: 0.6875rem;
}

.not-allowed {
  cursor: not-allowed;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.btn:hover {
  text-decoration: none;
}

input[type='checkbox'] {
  cursor: pointer;
}

.text-muted,
a.text-muted {
  color: $gray-600 !important;
}

i.text-muted {
  color: $gray-500 !important;
}

.text-more-muted,
a.text-more-muted {
  color: $gray-500 !important;
}

i.text-more-muted {
  color: $gray-400 !important;
}

.alert-primary {
  background-color: $primary-light;
}

.alert a {
  color: inherit;
}

.alert a {
  text-decoration: underline;
}

.text-yellow,
a.text-yellow:hover {
  color: $yellow;
}

.btn {
  @extend .fw-semibold;
}

.btn-check:checked + .btn,
.btn.btn-primary,
.btn.btn-primary:active,
.btn.btn-warning,
.btn.btn-warning:active {
  color: white;
}

.btn-group {
  background-color: white;
}

.btn-group .btn.btn-outline-primary:hover {
  background-color: $primary;
  color: white;
}

.btn-link {
  text-decoration: none;
}

form.inline {
  display: inline-block;
}

.form-label {
  @extend .fw-semibold;
}

th.shrink,
td.shrink {
  width: 0 !important;
  white-space: nowrap;
}
