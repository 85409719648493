body {
  background-color: #fafafa;
}

.container {
  max-width: 1920px;
}

#content {
  padding-top: 20px;
}

#dashboard {
  max-width: 960px;
  margin: 0 auto;
}

// To match filter dropdowns
.header .btn {
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;
  line-height: 30px;
}

.btn {
  @extend .fw-semibold;

  height: 2rem;
  padding: 0.375rem 0.5rem;
}

.e-btn {
  @extend .fw-semibold;
}

.modal-header {
  @extend .align-items-center;

  height: $navbar-height;
}

.due {
  @extend .text-danger;
}

.due-soon {
  @extend .text-warning;
}

.task-status {
  @extend .badge;
  @extend .py-1;

  min-width: 120px;
}

.task-status-unscheduled {
  background-color: $gray-500;
}

.task-status-scheduled {
  background-color: $gray-500;
}

.task-status-start-due {
  background-color: $primary;
}

.task-status-started {
  background-color: $yellow;
}

.task-status-completion-due {
  background-color: $orange;
}

.task-status-completed {
  background-color: $green;
}

.task-status-deadline-due {
  background-color: $red;
}

.read-only {
  @extend .text-muted;
  @extend .not-allowed;
}

div.table-like > div {
  @extend .d-flex;
  @extend .w-100;

  border-top: 1px solid $table-border-color;
}

div.table-like > div:first-child {
  border-top: none;
}

div.table-like > div > div {
  @extend .d-flex;
  @extend .align-items-center;

  padding: 0.5rem;
}

div.table-like > div > div:first-child {
  padding-left: 1rem;
}

div.table-like > div > div:last-child {
  padding-right: 1rem;
}
