@include export-module('inplaceeditor-layout') {
  .e-inplaceeditor {
    display: inline-block;
    position: relative;
    width: auto;

    .e-input-group.e-control-wrapper .e-clear-icon.e-clear-icon-hide {
      display: block;
      visibility: hidden;
    }

    .e-input-group.e-control-container .e-clear-icon.e-clear-icon-hide {
      display: block;
      visibility: hidden;
    }

    .e-editable-value-wrapper {
      display: inline-block;
      padding: $editor-nrml-value-container-padding;
      position: relative;
      width: auto;
      word-break: break-word;

      .e-editable-value {
        display: inline-block;
        font-family: $font-family;
        font-size: $editor-nrml-value-text-font-size;
        margin: $editor-nrml-value-margin;

        @if $skin-name == 'bootstrap4' {
          line-height: 24px;
        }
      }

      .e-editable-overlay-icon {
        bottom: 0;
        cursor: pointer;
        display: inline-flex;
        height: $editable-overlay-icon-height;
        position: absolute;
        right: $editor-nrml-overlay-icon-left-right;
        top: $editable-overlay-icon;
        visibility: hidden;
        width: $editor-nrml-overlay-icon-container-size;

        &::before {
          align-items: center;
          display: flex;
          font-size: $editor-nrml-overlay-icon-size;
        }
      }

      &.e-hide {
        display: none;
      }

      &.e-editable-open {
        user-select: none;
      }

      &.e-loading {
        pointer-events: none;
      }

      .e-spinner-pane .e-spinner-inner {
        left: unset;
        right: $editor-nrml-value-container-spin-padding;
      }

      &:hover {
        .e-editable-overlay-icon {
          visibility: visible;
        }

        &.e-editable-open {

          .e-editable-overlay-icon {
            visibility: hidden;
          }
        }
      }
    }

    .e-editable-value-container {
      display: inline-block;
      padding: $editor-nrml-value-container-padding;
      position: relative;
      width: auto;
      word-break: break-word;

      .e-editable-value {
        display: inline-block;
        font-family: $font-family;
        font-size: $editor-nrml-value-text-font-size;
        margin: $editor-nrml-value-margin;

        @if $skin-name == 'bootstrap4' {
          line-height: 24px;
        }
      }

      .e-editable-overlay-icon {
        bottom: 0;
        cursor: pointer;
        display: inline-flex;
        height: inherit;
        position: absolute;
        right: $editor-nrml-overlay-icon-left-right;
        top: 0;
        visibility: hidden;
        width: $editor-nrml-overlay-icon-container-size;

        &::before {
          align-items: center;
          display: flex;
          font-size: $editor-nrml-overlay-icon-size;
        }
      }

      &.e-hide {
        display: none;
      }

      &.e-editable-open {
        user-select: none;
      }

      &.e-loading {
        pointer-events: none;
      }

      .e-spinner-pane .e-spinner-inner {
        left: unset;
        right: $editor-nrml-value-container-spin-padding;
      }

      &:hover {
        .e-editable-overlay-icon {
          visibility: visible;
        }

        &.e-editable-open {
          .e-editable-overlay-icon {
            visibility: hidden;
          }
        }
      }
    }

    .e-editable-action-buttons {
      margin-top: $editor-buttons-top-margin;
      position: absolute;
      right: 0;
      z-index: 100;
    }

    &.e-overlay {
      user-select: none;
    }

    &.e-rtl {
      .e-editable-value-wrapper {
        .e-editable-value {
          margin: $editor-rtl-nrml-value-margin;
        }

        .e-editable-overlay-icon {
          left: $editor-nrml-overlay-icon-left-right;
          right: auto;
        }

        .e-spinner-pane {
          right: unset;
        }

        .e-spinner-pane,
        .e-spinner-pane .e-spinner-inner {
          left: $editor-nrml-value-container-spin-padding;
        }
      }

      .e-editable-value-container {
        .e-editable-value {
          margin: $editor-rtl-nrml-value-margin;
        }

        .e-editable-overlay-icon {
          left: $editor-nrml-overlay-icon-left-right;
          right: auto;
        }

        .e-spinner-pane {
          right: unset;
        }

        .e-spinner-pane,
        .e-spinner-pane .e-spinner-inner {
          left: $editor-nrml-value-container-spin-padding;
        }
      }

      .e-editable-action-buttons {
        left: 0;
        right: auto;
      }
    }
  }

  
  .e-inplaceeditor,
  .e-inplaceeditor-tip {
    .e-input-group + .e-editable-loading {
      @if $skin-name == 'material' {
        .e-spinner-inner {
          top: 35%;
        }
      }
    }

    .e-editable-elements:not(.e-richtexteditor) + .e-editable-loading {
      .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
        stroke-width: inherit;
      }
    }

    .e-input-group + .e-editable-loading {
      @if $skin-name == 'material' {
        .e-spinner-inner {
          top: 35%;
        }
      }
    }

    .e-editable-elements:not(.e-richtexteditor) + .e-editable-loading {
      .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
        stroke-width: inherit;
      }
    }

    .e-editable-loading {
      display: none;
      min-height: 100%;
      position: absolute;
      top: 0;

      &.e-show {
        display: block;

        &:not(.e-rte-spin-wrap) .e-spinner-inner {
          left: auto;
          right: 5px;
          transform: translateX(-30%) translateY(-50%);
        }
      }
    }

    .e-editable-form {
      width: 100%;

      &.e-loading {
        .e-input-group-icon {
          visibility: hidden;
        }
      }
    }

    .e-component-group {
      margin-bottom: $editor-component-bottom-margin;

      .e-editable-component {
        min-width: 150px;
        position: relative;
      }
    }

    .e-control-overlay {
      visibility: hidden;

      &.e-richtexteditor {
        opacity: .5;
        pointer-events: none;
        user-select: none;
        visibility: visible;
      }
    }

    .e-editable-action-buttons {
      .e-btn-save {
        margin: $editor-nrml-btn-save-margin;

        .e-btn-icon.e-icons {
          font-size: $editor-btn-save-icon-size;
        }
      }

      .e-btn-cancel {
        margin: $editor-nrml-btn-cancel-margin;

        .e-btn-icon.e-icons {
          font-size: $editor-btn-cancel-icon-size;
        }
      }

      &.e-hide {
        visibility: hidden;
      }
    }

    &.e-rtl {
      .e-editable-action-buttons {
        .e-btn-save {
          margin: $editor-rtl-nrml-btn-save-margin;
        }

        .e-btn-cancel {
          margin: $editor-rtl-nrml-btn-cancel-margin;
        }
      }

      .e-editable-loading.e-show:not(.e-rte-spin-wrap) .e-spinner-inner {
        left: 5px;
        right: auto;
        transform: translateX(30%) translateY(-50%);
      }
    }
  }

  

  .e-inplaceeditor-tip {
    opacity: 1;
    width: auto;

    &.e-tooltip-wrap {
      max-width: 100%;

      .e-input-group.e-control-wrapper .e-clear-icon.e-clear-icon-hide,
      .e-input-group.e-control-container .e-clear-icon.e-clear-icon-hide {
        display: block;
        visibility: hidden;
      }

      .e-tip-content {
        padding: 0;

        .e-editable-title {
          align-content: center;
          display: flex;
          flex-direction: column;
          font-family: $font-family;
          font-size: $editor-nrml-tip-title-font-size;
          font-weight: $editor-tip-title-font-weight;
          height: $editor-nrml-tip-title-container-size;
          justify-content: center;
          padding: $editor-nrml-tip-title-padding;
        }

        .e-editable-wrapper {
          display: flex;
          padding: $editor-nrml-tip-wrapper-padding;

          .e-editable-action-buttons {
            float: right;
            margin-top: $editor-buttons-top-margin;
            position: relative;
            right: auto;
            top: auto;
          }
        }

        .e-editable-container {
          display: flex;
          padding: $editor-nrml-tip-wrapper-padding;

          .e-editable-action-buttons {
            float: right;
            margin-top: $editor-buttons-top-margin;
            position: relative;
            right: auto;
            top: auto;
          }
        }

        .e-editable-title + .e-editable-wrapper {
          padding: $editor-nrml-wrapper-title-with-padding;
        }

        .e-editable-title + .e-editable-container {
          padding: $editor-nrml-wrapper-title-with-padding;
        }
      }
    }

    &.e-hide {
      visibility: hidden;
    }

    &.e-rtl {
      &.e-tooltip-wrap .e-tip-content {
        .e-editable-title {
          padding: $editor-rtl-nrml-tip-title-padding;
        }

        .e-editable-wrapper {
          .e-editable-action-buttons {
            float: left;
          }
        }

        .e-editable-container {
          .e-editable-action-buttons {
            float: left;
          }
        }
      }
    }
  }

  .e-content-placeholder.e-inplaceeditor.e-placeholder-inplaceeditor {
    background-size: 150px 60px;
    min-height: 60px;
  }

  @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    .e-control .e-inplaceeditor,
    .e-tooltip-wrap.e-popup.e-inplaceeditor-tip.e-popup-open,
    .e-inplaceeditor .e-input-group.e-control-wrapper.e-editable-elements {
      border-radius: 4px;
    }
  }

  @if $skin-name == 'tailwind' or $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
    .e-control.e-inplaceeditor .e-editable-value-wrapper {
      min-height: 30px;
    }
  }
}
