@import 'npm:@syncfusion/ej2-base/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-buttons/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-calendars/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-dropdowns/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-grids/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-inplace-editor/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-inputs/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-lists/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-popups/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-navigations/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-notifications/styles/bootstrap5.scss';
@import 'npm:@syncfusion/ej2-richtexteditor/styles/bootstrap5.scss';

// @import 'npm:@syncfusion/ej2-splitbuttons/styles/bootstrap5.scss';
// @import 'npm:@syncfusion/ej2-richtexteditor/styles/bootstrap5.scss';

.e-spinner-pane {
  background-color: rgba($color: #ffffff, $alpha: 0.5);
}

.e-error {
  padding-top: $table-cell-padding-y;
}

.e-input-group:has(.e-error) {
  border-color: $danger !important;
}

.e-input-focus:has(.e-error) {
  box-shadow: 0 0 $input-btn-focus-blur $input-focus-width
    rgba(var(--#{$prefix}danger-rgb), $input-btn-focus-color-opacity) !important;
}

.e-grid {
  border-radius: $border-radius;
}

.e-grid .e-gridheader {
  border-radius: $border-radius $border-radius 0 0;
}

.e-grid .e-gridcontent {
  border-radius: 0 0 $border-radius $border-radius;
}

.e-grid .e-gridheader,
.e-grid .e-headercell {
  background-color: #f5f5f5;
}

.e-grid .e-gridheader .e-headercontent {
  border: none;
}

// Remove padding from centre aligned templated (e.g. icon) header cells
.e-grid .e-gridheader .e-headercell.e-templatecell.e-centeralign .e-headercelldiv {
  padding: 0;
}

.e-ccdlg .e-dlg-header-content {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 35px;
  height: 35px;
}

// Hide column search
.e-cc-searchdiv {
  display: none;
}

.e-ccdlg .e-ccul-ele {
  margin-top: 0 !important;
}

.e-ccdlg .e-dlg-content {
  margin-top: 0 !important;
  padding: 0 0 0 5px !important;
}

// Hide column chooser down icon
.e-columnchooser-btn.e-icons {
  display: none;
}

.e-row {
  cursor: pointer;
}

// Increase column chooser width
.e-grid .e-ccdlg {
  width: 350px !important;
}

// Increase column chooser height
.e-grid .e-ccdlg .e-cc-contentdiv {
  height: 600px;
  max-height: 50vh;
}

.e-ccdlg.e-cc.e-control.e-dialog.e-lib.e-popup.e-popup-open {
  max-height: none !important;
}

.e-grid.e-gridhover tr:hover .e-rowcell,
.e-rowcell.e-selectionbackground.e-active {
  background-color: $table-hover-bg !important;
}

// Hide filter dropdown X button (not needed as we have the "Clear" button)
.e-popup-flmenu .e-clear-icon {
  visibility: hidden;
}

.e-chart path,
.e-chart text {
  cursor: pointer;
}

.e-multi-select-wrapper .e-chips {
  background-color: $primary;
}

.e-richtexteditor .e-rte-content .e-content {
  font-size: 14px;
  line-height: 1.5rem;
}

// Remove input padding top when error message visible
.e-input-group.e-control-wrapper.e-error {
  padding-top: 0;
}

.e-mention .e-mention-chip,
.e-mention .e-mention-chip:hover {
  // background-color: #ffffcc;
  background-color: transparent;
  // color: $gray-600;
}

.e-ddl .e-content .e-list-group-item {
  @extend .fs-7;
  @extend .text-muted;
  background-color: $card-cap-bg;
}

.e-ddl .e-content .e-list-item.e-item-focus {
  background-color: inherit;
}

.e-ddl .e-content .e-list-item:hover {
  background-color: $table-hover-bg;
}

.e-ddl .e-content .e-list-item.e-active {
  background-color: $primary;
}

.e-ddl .e-ddl-icon:hover {
  color: #ffffff !important;
}

// RTE - Insert Image - Hide option to add image by URL
.e-rte-img-dialog .e-img-content .e-linkheader,
.e-rte-img-dialog .e-img-content .imgUrl {
  display: none;
}

.e-rte-image.e-imgbreak {
  margin: 0;
}

// RTE - Create Link - Hide title field and tidy up styling
.e-rte-link-dialog .e-dlg-content {
  padding: 0 !important;
}

.e-rte-linkcontent .e-rte-label,
.e-rte-linkcontent .e-rte-field input[placeholder='Enter a title'] {
  display: none;
}

.e-rte-linkcontent .e-rte-field {
  margin: 1rem;
}

.e-rte-img-dialog .e-upload-file-list .e-file-name,
.e-rte-img-dialog .e-upload-file-list .e-file-type {
  display: none !important;
}
