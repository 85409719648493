@include export-module('accordion-bootstrap5-icons') {
  #{&}.e-accordion {

    .e-tgl-collapse-icon {

      &::before {
        content: '\e729';
      }

      &.e-expand-icon {
        transform: rotate(-180deg);
      }
    }
  }
}
