@include export-module('richtexteditor-theme') {

  /*! tab layout */

  .e-rte-container {
    border: 1px solid $rte-border-color;
  }

  .e-toolbar-wrapper,
  .e-toolbar-container  {
    border-bottom: 1px solid $rte-border-color;
  }

  iframe.e-rte-content {
    border: none;
  }

  .e-richtexteditor {
    color: $rte-content-color;
    background: $rte-content-bg;

    .e-rte-toolbar .e-toolbar-item.e-active{
      .e-tbar-btn,
      .e-tbar-btn:focus {
        background: $rte-tb-item-active-bg;
        border: $rte-tb-item-active-border;
  
        @if $skin-name == 'bootstrap5' {
          border: 0;
        }
      }
    }

    .e-toolbar-item.e-active {
      .e-tbar-btn .e-icons,
      .e-tbar-btn:focus .e-icons {
        color: $rte-tb-active-font-color;
      }
    }
    
    .e-toolbar-item {
      @if $skin-name == 'bootstrap5' {
        .e-tbar-btn:hover .e-icons {
          color: $rte-tb-hover-font-color;
        }
      }

      @if $skin-name != 'highcontrast' {
        &.e-overlay .e-tbar-btn .e-icons {
          color: $rte-ext-tbar-overlay-icons-color;
        }
      }
    }

    .e-dropdown-btn.e-active {
      background: $rte-drop-btn-active-bg;
      border-color: $rte-drop-btn-active-border-color;
    }

    @if $skin-name == 'bootstrap5' {
      .e-tbar-btn:hover .e-rte-dropdown-btn-text {
        color: $rte-tb-hover-font-color;
      }
    }
    
    .e-rte-toolbar {
      .e-rte-backgroundcolor-dropdown,
      .e-rte-fontcolor-dropdown,
      .e-rte-numberformatlist-dropdown,
      .e-rte-bulletformatlist-dropdown {
        &:hover {
          background: $rte-split-btn-hover-bg;
        }
      }
    }

    .e-toolbar-item {
      .e-rte-backgroundcolor-dropdown,
      .e-rte-fontcolor-dropdown,
      .e-rte-numberformatlist-dropdown,
      .e-rte-bulletformatlist-dropdown {
        &.e-active {
          background: $rte-split-btn-active-bg;
          border: $rte-split-btn-active-border;
        }
      }
    }

    .e-rte-toolbar {

      .e-toolbar-item {

        @if $skin-name != 'highcontrast' {
          .e-icons {
            color: $rte-icons-color;
          }
        }

        .e-dropdown-btn.e-active {
          .e-icons,
          .e-rte-dropdown-btn-text {
            color: $rte-drop-btn-active-color;
          }
        }

        .e-tbar-btn:hover {
          @if $theme-name == 'bootstrap5.3' {
            color: $rte-drop-btn-active-color;
          }
        }
      }

      .e-toolbar-extended {

        @if $skin-name != 'highcontrast' {
          .e-toolbar-item .e-tbar-btn .e-icons {
            color: $rte-icons-color;
          }
        }
        .e-toolbar-item .e-tbar-btn:hover .e-icons {
          color: $rte-hover-icons-color;
        }
        .e-toolbar-item.e-active .e-tbar-btn .e-icons {
          color: $rte-tb-active-font-color;
        }

        @if $skin-name != 'highcontrast' {
          .e-toolbar-item.e-overlay .e-tbar-btn .e-icons {
            color: $rte-ext-tbar-overlay-icons-color;
          }
        }

        .e-toolbar-item .e-dropdown-btn.e-active {
          background: $rte-drop-btn-active-bg;
        }

        .e-toolbar-item .e-tbar-btn:hover {
          @if $theme-name == 'bootstrap5.3' {
            color: $rte-drop-btn-active-color;
          }
        }

        @if $skin-name == 'bootstrap5' {
          .e-toolbar-item .e-tbar-btn:focus,
          .e-toolbar-item .e-tbar-btn:hover {
            border-radius: 4px;
          }
        }
      }
    }
    .e-toolbar-wrapper {
      background: $rte-tbar-default-bg;
    }

    img.e-img-focus::selection,
    audio.e-audio-focus::selection,
    .e-video-focus::selection {
      background: transparent;
      color: transparent;
    }
    @if $skin-name == 'Material3'  or $skin-name == 'fluent2' or $theme-name == 'fluent2-highcontrast' or $skin-name == 'fluent2-dark' {
      ::selection {
        color: $rte-selection-color;
        background: $rte-selection-bg;
      }
    }

    .e-rte-content,
    .e-source-content {
      background: $rte-content-bg;
      color: $rte-content-color;

      .e-content blockquote {
        border-left: $rte-content-blockquote-border-left-color;
      }

      .e-content .e-img-focus:not(.e-resize),
      .e-content .e-video-focus:not(.e-resize) {
        border: $rte-img-border;
      }

      .e-content a {
        color: $rte-anchor-color;
      }
    }

    &.e-rtl {
      .e-rte-content .e-content blockquote {
        border-left: 0;
        border-right: $rte-content-blockquote-border-left-color;
      }
    }

    .e-input.e-rte-linkurl.e-error,
    .e-input.e-img-link.e-error {
      color: $rte-link-valid-color;
    }

    &.e-rte-full-screen {
      background: $rte-full-screen-bg;
    }

    @if $skin-name == 'FluentUI' {
      .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn{
        border: 1px solid transparent;
        &:hover{
          border: 1px solid transparent;
        }
      }
      .e-toolbar .e-toolbar-item .e-tbar-btn{
        border: 1px solid transparent;
        &:hover{
          border: 1px solid transparent;
        }
      }
    }
  }
  @if $skin-name == 'bootstrap-dark' {
    .e-toolbar-extended .e-toolbar-item:not(.e-active) .e-tbar-btn{
      border: 1px solid transparent;
    }
    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
      .e-rte-backgroundcolor-dropdown:hover,
      .e-rte-fontcolor-dropdown:hover,
      .e-rte-numberformatlist-dropdown:hover,
      .e-rte-bulletformatlist-dropdown:hover {
        background: none;
      }
    }
  }

  @if $skin-name == 'highcontrast' {
    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
      .e-rte-backgroundcolor-dropdown:hover,
      .e-rte-fontcolor-dropdown:hover,
      .e-rte-numberformatlist-dropdown:hover,
      .e-rte-bulletformatlist-dropdown:hover {
        background: inherit;
      }
    }
  }
 
  .e-rte-inline-popup,
  .e-richtexteditor {
    .e-rte-backgroundcolor-dropdown,
    .e-rte-fontcolor-dropdown,
    .e-rte-numberformatlist-dropdown,
    .e-rte-bulletformatlist-dropdown {

      &:hover .e-rte-color-content::after {
        background: $rte-split-btn-bar-bg;
      }

      &:active:hover {

        .e-icons {
          color: $rte-split-btn-color;
        }

        @if $skin-name == 'bootstrap5' or $skin-name == 'tailwind' {
          .e-icons {
            color: $rte-tb-hover-font-color;
          }
        }

        .e-caret:active.e-icons,
        .e-rte-color-content:active .e-icons,
        .e-rte-list-primary-content:active .e-icons {
          color: $rte-split-btn-active-color;
        }
      }
      
      &:hover {

        .e-rte-color-content,
        .e-rte-list-primary-content {

          &:hover {
            @if $theme-name == 'fluent2-highcontrast' {
              background: $rte-split-btn-span-active-bg;
            }
            @else {
              background: $rte-split-btn-span-hover-bg;
            }
          }

          &:focus {
            background: $rte-split-btn-span-focus-bg;
          }

          &:active {
            background: $rte-split-btn-span-active-bg;
          }
        }

        .e-caret {

          &:hover {
            @if $theme-name == 'fluent2-highcontrast' {
              background: $rte-split-btn-span-active-bg;
            }
            @else {
              background: $rte-split-btn-span-hover-bg;
            }
          }

          &:focus {
            background: $rte-split-btn-span-focus-bg;
          }

          &:active {
            background: $rte-split-btn-span-active-bg;
          }
        }
      }

      &.e-active {
        background: $rte-split-btn-active-bg;
        border: $rte-split-btn-active-border;

        .e-rte-color-content::after {
          background: $rte-split-btn-active-bar-bg;
        }

        &:hover .e-caret:hover,
        &:hover .e-rte-color-content:hover,
        &:hover .e-rte-list-primary-content:hover {
          background: transparent;
        }

        .e-icons,
        &:active .e-icons {
          color: $rte-split-btn-active-color;
        }
      }
    }
  }
    
  .e-rte-quick-popup {
    border: $rte-quick-pop-border;
    border-radius: 2px;
    box-shadow: $rte-quick-pop-shadow;

    .e-rte-quick-toolbar {
      background: $rte-quick-pop-bg;
      border: $rte-quick-tb-border;

      .e-toolbar-items {
        background: $rte-quick-pop-bg;

        .e-rte-backgroundcolor-dropdown {
          .e-rte-color-content{
            padding-top: $rte-dropdown-btn-color-content-padding;
            height: $rte-dropdown-btn-color-content-height;
            @if $skin-name == 'FluentUI' {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:hover {
              @if $theme-name == 'fluent2-highcontrast' {
                background: $rte-split-btn-span-active-bg;
              }
              @else {
                background: $rte-split-btn-span-hover-bg;
              }
            }
          }

          .e-caret {
            &:hover {
              @if $theme-name == 'fluent2-highcontrast' {
                background: $rte-split-btn-span-active-bg;
              }
              @else {
                background: $rte-split-btn-span-hover-bg;
              }
            }
          }
          &.e-tbar-btn:hover {
            @if $skin-name == 'tailwind' or $skin-name == 'tailwind-dark' or $skin-name == 'highcontrast' {
              background: $rte-split-btn-hover-bg;
            }
            @if $skin-name =='bootstrap-dark'{
              background: $rte-tbar-default-bg;
            }
          }
        }

        .e-separator {
          border: .5px solid $rte-quick-vr-line-color;
        }

        .e-tbar-btn {
          background: $rte-quick-pop-bg;
          border: $rte-quick-item-border;
        }

        .e-tbar-btn:hover {
          background: $rte-quick-tb-btn-hover;
          border-color: $rte-quick-item-hover-border-color;
        }

        @if $skin-name == 'bootstrap5' or $skin-name == 'bootstrap5.3' {
          .e-rte-dropdown-btn-text {
            color: $rte-icons-color;
          }

          .e-tbar-btn:hover .e-rte-dropdown-btn-text {
            color: $rte-quick-item-active-font-color;
          }
        }
        .e-tbar-btn:focus {
          background: $rte-quick-pop-item-focus-bg;
        }

        .e-tbar-btn:active {
          background: $rte-quick-item-active-bg;
          border-color: $rte-quick-item-active-border-color;

          .e-icons {
            color: $rte-quick-item-active-font-color;
          }
        }

        .e-toolbar-item.e-active .e-tbar-btn,
        .e-toolbar-item.e-active .e-tbar-btn:focus {
          background: $rte-tb-item-active-bg;
          border: $rte-tb-item-active-border;
        }

        .e-toolbar-item.e-active .e-tbar-btn .e-icons,
        .e-toolbar-item.e-active .e-tbar-btn:focus .e-icons {
          color: $rte-tb-active-font-color;
        }

        .e-icons {
          color: $rte-icons-color;
        }

        @if $skin-name == 'bootstrap5' {
          .e-tbar-btn:hover .e-icons {
            color: $rte-tb-hover-font-color;
          }

          .e-tbar-btn:focus .e-icons {
            color: $primary-text-focus;
          }
        }
      }

      .e-toolbar-extended {

        .e-toolbar-item .e-tbar-btn .e-icons {
          color: $rte-icons-color;
        }
      }
    }

    .e-rte-dropdown-btn {
      background: $rte-quick-pop-bg;
      border-color: $rte-quick-drop-btn-border-color;

      &:focus,
      &:hover {
        background: $rte-quick-tb-btn-hover;
      }

      &:hover {
        border-color: $rte-quick-drop-btn-hover-border-color;

        @if $skin-name == 'highcontrast' {
          border-width: $rte-2px-border-size;
        }
      }
    }
    .e-rte-quick-toolbar .e-toolbar-items {
      .e-rte-fontcolor-dropdown .e-rte-color-content:hover,
      .e-rte-numberformatlist-dropdown .e-rte-list-primary-content:hover,
      .e-rte-bulletformatlist-dropdown .e-rte-list-primary-content:hover {
        background: $rte-split-btn-span-hover-bg;
      }
    }
    .e-rte-quick-toolbar .e-toolbar-items {
      .e-rte-fontcolor-dropdown .e-caret:hover,
      .e-rte-numberformatlist-dropdown .e-caret:hover,
      .e-rte-bulletformatlist-dropdown .e-caret:hover {
        background: $rte-split-btn-span-hover-bg;
      }
    }
  }

  .e-linkheader {
    color: $rte-img-header-clr;
  }

  .e-rte-img-popup.e-popup {
    background: $rte-full-screen-bg;
    border: $rte-img-popup-border;
    box-shadow: $rte-img-popup-box-shadow;
    color: $rte-img-popup-color;
    padding: 10px;
  }

  .e-rte-content .e-content img:not(.e-resize) {
    z-index: 1000;
  }

  span.e-rte-imageboxmark,
  span.e-rte-videoboxmark {
    background: $rte-img-resize-back-color;
    border: 1px solid $rte-img-resize-color;
    display: block;
    height: 10px;
    position: absolute;
    width: 10px;
    z-index: 1000;
  }

  .e-mob-rte span.e-rte-imageboxmark,
  .e-mob-rte span.e-rte-videoboxmark {
    background: $rte-img-resize-color;
    border: 1px solid $rte-img-resize-back-color;
    border-radius: 15px;
    height: 20px;
    width: 20px;
  }

  .e-mob-rte.e-mob-span span.e-rte-imageboxmark,
  .e-mob-rte.e-mob-span span.e-rte-videoboxmark {
    background: $rte-img-resize-back-color;
    border: 1px solid $rte-img-resize-color;
  }

  .e-rte-content .e-content img.e-resize,
  .e-rte-content .e-content video.e-resize {
    z-index: 1000;
  }

  #{if(&, '&', '*')}.e-disabled {
    user-select: none;
    .e-rte-toolbar .e-toolbar-item {
      cursor: default;
      & .e-tbar-btn.e-icon-btn,
      & .e-dropdown-btn.e-btn,
      & .e-colorpicker-wrapper .e-btn {
        &:hover,
        &:active,
        &:focus {
          background: transparent;
          cursor: default;
        }
      }
    }
  }

  .e-dropdown-popup {
    & ul {
      & .e-item.e-active {
        background: $rte-dropdown-selection-bgcolor;
        color: $rte-dropdown-selection-color;
      }
    }
  }

  .e-popup-overlay {
    background-color: $rte-overlay-color;
  }

  .e-rte-toolbar.e-extended-toolbar .e-toolbar-item.e-overlay .e-dropdown-btn {
    color: $rte-ext-tbar-overlay-dropdown-color;
  }

  .e-rte-table-popup.e-popup.e-popup-open {
    background-color: $rte-content-bg;
    color: $rte-item-color;
  }

  .e-richtexteditor .e-rte-toolbar .e-hor-nav:hover{
    border: solid rgba(0, 0, 0, .12);
    border-width: $rte-toolbar-hor-nav-border-width;
    padding-left: $rte-tb-expended-hover-padding-left;
  }

  @if $skin-name == 'bootstrap5' {
    .e-rte-table-popup.e-popup.e-popup-open {
      background-color: $rte-table-popup-bg;
    }
  }

  .e-rte-table-popup .e-span-border {
    @if $skin-name != 'Material3' {
      border: 1px solid $rte-border-color;
    }
    display: block;
    margin-bottom: 8px;
    margin-top: 12px;
  }

  .e-rte-table-popup .e-rte-popup-header {
    color: $rte-table-header-color;
    font-family: $rte-font-family;
    font-size: 14px;
    text-align: center;
  }

  .e-rte-table-popup.e-popup-open .e-rte-tablecell.e-default {
    background-color: $rte-table-span-bg-color;
  }

  .e-rte-table-popup.e-popup-open .e-rte-tablecell.e-active {
    background-color: $rte-table-span-active-bg-color;
    border: 1px solid $rte-table-span-active-border-color;
  }

  .e-rte-table.e-alternate-border tbody tr:nth-child(2n+0) {
    background: $rte-table-alternate-color;
    @if $skin-name == 'highcontrast' {
      color: $rte-img-popup-color;
    }
  }

  .e-rte-table th {
    background: $rte-table-header-bg;
    @if $skin-name == 'highcontrast' {
      color: $rte-table-header-text-color;
    }
  }

  .e-rte-table-popup.e-popup.e-popup-open {
    background-color: $rte-table-popup-bg;
    color: $rte-table-popup-color;
  }

  span.e-table-box {
    background-color: $rte-table-resize-back-color;
    border: 1px solid $rte-table-border-color;
  }

  span.e-table-box.e-rbox-select {
    background-color: $rte-table-border-color;
    border: 1px solid $rte-table-border-color;
  }

  .e-table-rhelper {
    background-color: $rte-table-span-active-border-color;
  }

  .e-rte-dialog-upload.e-upload.e-lib.e-keyboard {
    background: $rte-content-bg;
  }

  .e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item {
    &.e-active .e-tbar-btn .e-icons,
    & .e-tbar-btn:active .e-icons,
    & .e-tbar-btn:focus .e-icons,
    & .e-tbar-btn:hover .e-icons {
      color: $rte-quick-item-active-font-color;
    }
    @if $skin-name == 'bootstrap5' {
      & .e-tbar-btn:hover {
        color: $rte-quick-item-active-font-color;
      }
    }
  }
  
  .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-active .e-icons {
    @if $theme-name == 'fluent2-highcontrast' {
      color: $rte-drop-btn-active-color;
    }
    @else {
      color: $rte-tlbar-expand-active;
    }
  }

  .e-rte-emojipicker-popup.e-popup.e-popup-open {
    background: $rte-emoji-pop-background;
    box-shadow: $rte-emoji-pop-box-shadow;
    border: $rte-emoji-pop-border;

    @if $skin-name =='bootstrap5' or $skin-name =='botstrap5-dark' or $skin-name =='bootstrap' or $skin-name =='bootstrap-dark' or $skin-name =='bootstrap4' {
      filter: drop-shadow(0 0 6px rgba(0, 0, 0, .25));
    }
    
    @if $skin-name =='bootstrap5' or $skin-name =='botstrap5-dark' or $skin-name =='bootstrap4' {
      .e-toolbar .e-toolbar-item .e-tbar-btn.e-selected .e-icons {
        color: $rte-emoji-tbar-btn-selected-font-color;
      }
    }

    .e-toolbar .e-toolbar-item .e-tbar-btn {
      background: $rte-emoji-tbar-btn-bg;

      @if $skin-name =='bootstrap5' {
        &:focus{
          background: $rte-tbar-default-bg;
        }
      }
      &.e-selected {
        background: $rte-emoji-tbar-btn-select-bg;
      }
    }

    .e-toolbar .e-toolbar-item .e-tbar-btn:hover {
      background: $rte-emoji-tbar-btn-hover;
    }

    .e-rte-emojipicker-toolbar {
      background: transparent;
      border: none;
      box-shadow: none;

      & .e-toolbar-items {
        background: transparent;

        & .e-scroll-nav {
          border: none;
        }
      }
    }

    .e-rte-emojipicker-btn {
      .e-rte-emojipicker-group .e-rte-emojipickerbtn-group {
        .e-btn.e-control {
          @if $skin-name =='bootstrap4' {
            color: inherit;
          }

          &:not(:hover):not(:focus):not(:active) {
            background: transparent;
          }
        }
      }

      .e-rte-emojisearch-btn {
        .e-btn.e-control {
          @if $skin-name =='bootstrap4' {
            color: inherit;
          }

          &:not(:hover):not(:focus):not(:active) {
            background: transparent;
          }
        }
      }
      .e-rte-emojipicker-group .e-rte-emojipicker-name {
        color: $rte-emoji-headname-color;
      }
    }
  }
  @if $skin-name == 'tailwind' {
    .e-richtexteditor .e-rte-toolbar .e-toolbar-extended {
      background: $rte-extended-toolbar-background;
    }

    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons {
      color: $rte-icons-color;
    }

    .e-richtexteditor {
      .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-overlay .e-tbar-btn .e-icons {
        color: $icon-color-disabled;
      }
    }

    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover {
      border-radius: 4px;
    }

    .e-richtexteditor .e-toolbar .e-tbar-btn {
      border: 1px solid $transparent;
    }

    .e-richtexteditor .e-toolbar .e-tbar-btn:hover {
      background: $rte-tb-hover-bg-color;
      border: 1px solid $rte-quick-item-hover-border-color;
    }

    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover {
      background: $rte-tb-hover-bg-color;
      border: 1px solid $rte-quick-item-hover-border-color;
    }

    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn{
      border: 1px solid $transparent;
    }

    .e-richtexteditor .e-toolbar .e-toolbar-item .e-tbar-btn:focus {
      border: 1px solid $transparent;
    }
  }

  @if $skin-name == 'bootstrap5' {
    .e-richtexteditor .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active {
      background: $rte-tb-item-active-bg;
      border-radius: 4px;
    }

    .e-richtexteditor .e-toolbar .e-insert-table-btn.e-btn:hover .e-icons {
      color: $rte-tb-hover-font-color;
    }
    .e-rte-dropdown-popup .e-active.e-item .e-menu-icon {
      color: $white;
    }
  }

  @if $skin-name == 'Material3' {
    .e-richtexteditor .e-rte-table-popup .e-insert-table-btn {
      color: $rte-content-color;
    }
  }

  @if $skin-name == 'material-dark' or $skin-name == 'fabric' or $skin-name == 'fabric-dark' or $skin-name == 'bootstrap' or $skin-name == 'bootstrap-dark' or $skin-name == 'bootstrap4' {
    .e-richtexteditor .e-toolbar,
    .e-richtexteditor .e-toolbar .e-toolbar-pop{
      border: none;
    }
  }

  @if $skin-name == 'fluent2' {
    .e-richtexteditor .e-toolbar {
      box-shadow: none;
    }
    .e-rte-emojipickerbtn-group .e-btn:hover {
      background: $rte-emoji-tbar-btn-hover;
    }
  }

  // Blazor styles start

  @if $skin-name == 'bootstrap5' or $skin-name == 'tailwind' {
    .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:active .e-icons,
    .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:active .e-rte-dropdown-btn-text,
    .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:focus .e-rte-dropdown-btn-text,
    .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-icon-button:focus .e-icons {
      color: $rte-quick-item-active-font-color;
    }
  }

  .e-rte-img-dialog .e-rte-upload-progress {
    color: $rte-dialog-upload-status-progress;  /* stylelint-disable-line declaration-no-important */
  }
  // Blazor styles end
}
