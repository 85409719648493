@include export-module('datepicker-bootstrap5-icons') {

  /*! component icons */

  .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: $datepicker-icon-font-size;
    margin: $datepicker-icon-normal-margin;
    min-height: $datepicker-icon-container-min-height;
    min-width: $datepicker-icon-container-min-width;
    outline: none;

    #{if(&, '&', '*')}::before {
      content: '\e75e';
      font-family: 'e-icons';
    }

    #{if(&, '&', '*')}:focus {
      background: $datepicker-icon-hover-color;
      border-radius: $datepicker-icon-border-radius;
    }
  }

  // mouse small icon
  .e-small .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-control-wrapper.e-small .e-input-group-icon.e-date-icon,
  #{if(&, '&', '*')}.e-small .e-control-wrapper .e-input-group-icon.e-date-icon {
    font-size: $datepicker-small-icon-font-size;
  }

  // Touch small icon
  .e-datepick-mob-popup-wrap {
    
    #{if(&, '&', '*')} .e-datepicker.e-popup-expand {
  
      #{if(&, '&', '*')} .e-model-header {
  
        #{if(&, '&', '*')} .e-popup-close::before {
          content: '\e7e7';
          font-family: 'e-icons';
        }
      }

      #{if(&, '&', '*')} .e-calendar .e-header .e-date-icon-next::before {
        content: '\e748';
      }
      #{if(&, '&', '*')} .e-calendar .e-header .e-date-icon-prev::before {
        content: '\e765';
      }
    }
  }
}
