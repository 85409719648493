.button {
  min-width: 30px;
}

.button[data-toggled] {
  transition: opacity 0.2s;
}

.button[data-toggled='false'] {
  opacity: 0.3;
}

.button[data-toggled='false']:hover {
  opacity: 0.6;
}

.button[data-toggled='true'] {
  opacity: 1;
}

.button[data-toggled='true']:hover {
  opacity: 0.8;
}

.button[data-hide='true'] {
  display: none;
}

.btn-delete {
  color: $gray-400;
}

.btn-delete:hover {
  color: $danger;
}
