@include export-module('inplaceeditor-bootstrap-icons') {

  /*! inplaceeditor icons */
  .e-inplaceeditor,
  .e-inplaceeditor-tip {

    .e-save-icon::before {
      content: '\e774';
    }

    .e-cancel-icon::before {
      content: '\e7e7';
    }

    .e-editable-overlay-icon::before {
      content: '\e730';
    }
  }
}
