.editable-wrapper,
.editable {
  display: inline-block;
}

.editable-wrapper:has(.editable.e-disable),
.e-readonly {
  cursor: not-allowed;
}

.editable.e-disable .e-editable-value {
  @extend .text-muted;
}

.editable[data-empty='true'] .e-editable-value {
  @extend .text-more-muted;
}

.editable[data-obscured='true']:not([data-empty='true']) .e-editable-value {
  visibility: hidden;
}

.editable[data-obscured='true']:not([data-empty='true']) .e-editable-value:before {
  content: '••••••••••';
  visibility: visible;
  @extend .text-more-muted;
}

.editable[data-empty='true'] + span {
  display: none;
}

.e-inplaceeditor .e-editable-value-wrapper {
  cursor: pointer;
}

.e-inplaceeditor:hover .e-editable-value-wrapper {
  background-color: $card-cap-bg;
}

.editable-wrapper:has(.editable[data-fullwidth='true']):has(.e-control),
.editable[data-fullwidth='true']:has(.e-control-wrapper),
.editable[data-fullwidth='true']:has(.e-richtexteditor),
.e-inplaceeditor[data-fullwidth='true']:has(.e-control-wrapper) {
  display: block;
}

.e-inplaceeditor[data-fullwidth='true'] .e-editable-value-wrapper.e-hide {
  display: none;
}

.e-editable-error {
  @extend .mt-1;
}

.e-control-wrapper textarea {
  min-height: 100px !important;
  max-height: 300px !important;
}

.editable[data-multiline='true']:not([data-empty='true']).editable-text .e-editable-value-wrapper {
  max-height: 90px !important;
  overflow: hidden;
  margin-bottom: -4px; // For some reason 4px is added to height of multiline text box
}

.e-switch-wrapper:has(.editable:not([data-new='true'])) {
  @extend .ms-2;
}

.e-inplaceeditor .e-editable-action-buttons {
  right: auto;
  left: 0px;
  min-width: 150px;
}

.e-inplaceeditor .e-component-group .e-editable-component {
  min-width: auto;
}

.editable[data-read-only='true'] .e-input-group.e-control-wrapper,
.editable[data-read-only='true'] .e-input-group.e-control-wrapper.e-ddl {
  .e-input {
    @extend .text-muted;
    cursor: not-allowed;
    background-color: $card-cap-bg;
  }
}

.editable[data-manual-entry='false']:not([data-read-only='true']) .e-input-group.e-date-wrapper {
  .e-input {
    cursor: pointer;
    color: $input-color;
    background-color: $input-bg;
  }

  .e-input-group-icon {
    background-color: #fff !important;
  }
}
