#offcanvas {
  @extend .offcanvas;
  @extend .offcanvas-end;

  z-index: 10000; // Overlay Help Scout Beacon (obscures bottom field)
  width: 800px;
}

@media screen and (max-width: 800px) {
  #offcanvas {
    width: 100%;
    max-width: none;
  }
}

#offcanvas.expanded {
  width: 100%;
  max-width: none;
}

#offcanvas iframe {
  width: 100%;
}

body.offcanvas-layout {
  #content {
    @extend .container-fluid;

    padding-top: $navbar-height + 20px;
  }

  .header {
    @extend .flex-nowrap;
    @extend .fixed-top;
    @extend .align-items-center;

    height: $navbar-height;
    background-color: $navbar-background-color;
    color: #fff;

    > :first-child {
      min-width: 0;

      > h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 20px;
        line-height: $navbar-height;
      }
    }

    > :last-child {
      white-space: nowrap;
      margin: 0 20px;
    }
  }
}

.header {
  @extend .d-flex;
  @extend .flex-wrap;

  :first-child {
    @extend .flex-grow-1;
  }

  :last-child {
    > a,
    > div {
      margin-right: 0.5rem;
    }

    :last-child {
      margin-right: 0;
    }
  }
}
