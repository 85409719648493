.search-dropdown {
  @extend .p-2;

  background-color: $primary-light;
}

.search-result-group {
  @extend .fw-bolder;
  @extend .border-bottom;

  color: $primary;
}

.search-result-item span:not(:first-child) {
  @extend .text-more-muted;
  @extend .ms-3;
}
