.navbar {
  @extend .fixed-top;
  @extend .navbar-expand-lg;

  padding: 0;
}

.navbar-dark {
  background-color: $navbar-background-color;
}

.navbar-collapse,
.navbar-toggler {
  min-height: $navbar-height;
}

.navbar-toggler,
.navbar-toggler:focus {
  border: none;
  box-shadow: none;
}

.navbar-toggler i {
  font-size: 1rem;
}

.navbar-collapse.show {
  height: auto;
}

.navbar-brand:hover {
  text-decoration: none;
}

// Logo aspect ratio = 1 / 0.895
.navbar-brand img {
  margin: 0;
  padding: 0;
  line-height: 0;
  width: calc(1 * $navbar-height / 2.2);
  height: calc(0.895 * $navbar-height / 2.2);
}

.navbar .dropdown-menu {
  margin: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}

.navbar .nav-link {
  padding: 3px 1rem 0 1rem;
  line-height: $navbar-height - 6px;
  border-bottom: 3px solid transparent;
}

.navbar .nav-link.active {
  border-color: $primary;
}

.nav-item i {
  font-size: 1rem;
}

.nav-link:hover,
.dropdown-item:hover {
  text-decoration: none;
}
